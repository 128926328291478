import './descuento.css'
import { Modal, Button } from 'react-bootstrap';
import QRCode from "react-qr-code";
import {Link} from 'react-router-dom';

export default function Cupon({cupon, modal,close,isQr = false,isMsg = false, isError = false}) {


    if(isError){
        return(
            <Modal  centered contentClassName='bg-transparent border-0 ' show={modal} onHide={close}>
                <div className='ContainCodCupon p-4 text-center  p-auto m-auto'>
                <div>
                    <h2 className='TitleCupon pb-3'>Solo válido para suscriptores, pedí tu credencial ahora</h2>
                </div>
                <div className='text-center'>
                    <Link to='/suscripcion'>
                        <Button onClick={close} bsPrefix='btnSoli'>
                        ¡Quiero mi credencial ahora!
                        </Button>
                    </Link>
                    <Link  to ='/login'>
                    <p style={{color:'var(--white)',fontSize:'10px'}} className='pt-2'>Ya tengo una cuenta</p>
                    </Link>
                </div>
                
                </div>
            </Modal>
        )
    }
 
    if(isMsg){
        return(
            <Modal centered contentClassName='bg-transparent border-0 ' show={modal} onHide={close}>
                <div className='ContainCodCupon p-4 text-center  p-auto m-auto'>
                    <h2 className='TitleCupon pb-3'>{cupon}</h2>
                </div>
            </Modal>
        )
    } 
   else if(isQr){
        return(
            <Modal centered contentClassName='bg-transparent border-0 ' show={modal} onHide={close}>
                <div className='ContainCodCupon p-4 text-center  p-auto m-auto'>
                    <h2 className='TitleCupon pb-3'>Tu QR es:</h2>
                    <h2 className='TitleCupon'>{cupon}</h2>
                    <div style={{backgroundColor:'white'}}>
                    <QRCode value={cupon} size={200} />
                    </div>
                </div>
            </Modal>
        )

    }else{
        let codigo = cupon.cod_promocional.split('');
        return(
            <Modal centered contentClassName='bg-transparent border-0 ' show={modal} onHide={close}>
                <div className='ContainCodCupon p-4 text-center  p-auto m-auto'>
                    <h2 className='TitleCupon pb-3'>Tu cupón de descuento es:</h2>
                <h2 className='CodCupon p-2'>
                    {
                    codigo.map((item,index)=>
                    {
                        return(
                            <span className='px-1' style={{textDecoration:'underline'}} key={index}>{item}</span>
                            )
                    })}
                </h2>
                </div>
            </Modal>
    )
}

}

