import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { useState } from "react";
import Divider from "../Divider";


export default function AccordionDescuento({title,content,setFiltro,filtro, subtitle, divider}) {
  const handleAdd = (nombre) => {
    if(!filtro.includes(nombre)){
    setFiltro(filtro => [...filtro, nombre]);
    }
  };

    const [open, setOpen] = useState(true);
  return (
    <>
    <div className="d-flex flex-row align-items-center justify-content-between pb-2">
        {subtitle ? <p className="leftMenu-value ">{title}</p>:<p className="PRubro ">{title}</p>}
        { open ? <FaAngleUp className="cursor-pointer" fontSize='17px' onClick={() => setOpen(false)} /> : <FaAngleDown className="cursor-pointer" fontSize='17px' onClick={() => setOpen(true)} /> }
    </div>
    { open ? <div className="container ">

      {content.map((item,index) => {
        if(!item.nombre){
          return(
            <p key={index} onClick={()=>handleAdd(item.descprov)} style={{cursor:'pointer'}} className='PCategoria'>{item.descprov}</p>
          )
        }
        return(
          <p key={index} onClick={()=>handleAdd(item.nombre)} style={{cursor:'pointer'}} className='PCategoria'>{item.nombre}</p>
        )
      })}
    </div> : null }
    <div className="pt-3">
    {divider && <Divider isHorizontal style={{backgroundColor:"var(--grey)" }} />}
    </div>
    </>
  )
}
