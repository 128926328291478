import { Button, Row, Col} from 'react-bootstrap';
import Logo from '../Logo';
import Divider from '../Divider';
import { Link } from 'react-router-dom';
import './header.css';
import Menu from '../../assets/images/menu.svg';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const showMenu = () => {
  const menu = document.getElementsByClassName('HeaderContain')[0];
  const menuItems= document.getElementsByClassName('MobileMenu')[0];
  menuItems.classList.toggle('Show');
  menu.classList.toggle('FullHeight');
}
const closeMenu = () => {
  window.scrollTo( 0, 0 );
  const menu = document.getElementsByClassName('HeaderContain')[0];
  const menuItems= document.getElementsByClassName('MobileMenu')[0];
  menuItems.classList.remove('Show');
  menu.classList.remove('FullHeight');
}

const LogOut = () => {
  localStorage.removeItem('idbeneficiario');
  localStorage.removeItem('nombre');
  window.location.href = '/';
}

export default function Header() {
  let location = useLocation();
  const [login, setLogin] = useState();

  useEffect(() => {
    if (localStorage.getItem('idbeneficiario')) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  , [location])


  return (<>
    <div className="HeaderContain container-fluid  pb-3 ">
      <div className="HeaderContainItems  container-lg ">
      <div className="HeaderLogo">
        <Link onClick={closeMenu}  to="/">
          <Logo />
        </Link>
      </div>
        <div className='SubtitleContain HeaderHideMobile'>
          <Link onClick={closeMenu} to="/cultural">
            <p className='Subtitle cursor-pointer'  textContent='CULTURA'>CULTURAL</p>
          </Link>
          <Link onClick={closeMenu} to="/descuentos">
            <p className='Subtitle cursor-pointer' textContent='DESCUENTOS'>DESCUENTOS</p>
          </Link>
          <Link onClick={closeMenu} to="/escapadas">
            <p className='Subtitle cursor-pointer' textContent='ESCAPADAS'>ESCAPADAS</p>
          </Link>
          <Link onClick={closeMenu} to="/marketplace">
            <p className='Subtitle cursor-pointer' textContent='MARKETPLACE'>MARKETPLACE</p>
          </Link>
        </div>
      {!login &&  <Link onClick={closeMenu}  to="/suscripcion" >
          <Button bsPrefix='btnSoli HeaderHideMobile'>SOLICITAR MI CREDENCIAL </Button>
        </Link>
        }
        <img src={Menu} onClick={showMenu}  className='Menu' alt='Menu'/>
        
        </div>
        <div className='MobileMenu '>
          <Row className="justify-content-center text-center pt-5">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
              <Link onClick={closeMenu} to="/cultural">
                <p className='PMenuMobile p-3'>CULTURAL</p>
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center text-center ">
            <Col md={6}>
              <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
              <Link onClick={closeMenu} to="/descuentos">
                <p className='PMenuMobile p-3'>DESCUENTOS</p>
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
              <Link onClick={closeMenu} to="/escapadas">
                <p className='PMenuMobile p-3'>ESCAPADAS</p>
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} /> 
              <Link onClick={closeMenu} to="/marketplace">
                <p className='PMenuMobile p-3'>MARKETPLACE</p>
              </Link>
            </Col>
          </Row>
         {!login && <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
            <div className='p-3'>
              <Link to="/suscripcion" >
                <Button onClick={closeMenu} bsPrefix='btnSoli'>SOLICITAR MI CREDENCIAL </Button>
              </Link>
            </div>
            </Col>
          </Row>}
          <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />

            <p className='PMenuMobile p-3'>PREGUNTAS FRECUENTES</p>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
            <Link to="/contacto" onClick={closeMenu}>
              <p className='PMenuMobile p-3'>CONTACTO</p>
            </Link>
            </Col>
          </Row>
         {!login && <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
            <div className='p-3'>
            <Link onClick={closeMenu} to="/login" >
              <Button  bsPrefix='btnSoli'>INICIAR SESION</Button>
            </Link>
            </div>
            </Col>
          </Row>}
          {login && <Row className="justify-content-center text-center">
            <Col md={6}>
            <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
            <div className='p-3'>
            <Link onClick={closeMenu} to="/micuenta" >
              <Button  bsPrefix='btnSoli'>MI CUENTA</Button>
            </Link>
            </div>
            </Col>
            </Row>
          }
          {login && <Row className="justify-content-center text-center">
            <Col md={6}>
              <Divider isHorizontal  style={{backgroundColor:"var(--grey)"}} />
              <div className='p-3'>
                <Link onClick={LogOut} to="/" >
                  <p className='PMenuMobile p-3' style={{textDecoration:'underline'}} >CERRAR SESION</p>
                </Link>
              </div>
            </Col>
            </Row>
          }

        </div>
        
    </div>
    </>
  )
}
