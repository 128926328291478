import Welcome from '../../assets/images/hola_usuario.png';
import Divider from '../Divider';
import './profile.css';

export default function SubMenu({setPantalla, pantalla}) {
    
    const active = {color: 'var(--black)', fontWeight: '600'};
    const LogOut = () => {
        localStorage.removeItem('idbeneficiario');
        localStorage.removeItem('nombre');
        window.location.href = '/';
      }
  return (
    <>
        <div className='ps-4'> 
            <img src={Welcome} alt=""/>
        </div>
        <div className='px-3 mt-4 contain-myaccount'>
        <p className='py-3 sub-menu-categoria'>Mi cuenta</p>
        <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />

        <p 
        onClick={() => setPantalla(1)}
        style={pantalla === 1 ? active : null}
        className='sub-menu-categoria pt-3 pb-1 '>
        
            Perfil
        </p>
        <p 
        onClick={() => setPantalla(2)}
        style={pantalla === 2 ? active : null}
        className='sub-menu-categoria pt-3 pb-1'>
            Mis Direcciones
            </p>
        <p 
        onClick={() => setPantalla(3)}
        style={pantalla === 3 ? active : null}
        className='sub-menu-categoria pt-3 pb-1'
        >
            Mis Compras</p>
        <p 
        onClick={() => setPantalla(4)}
        style={pantalla === 4 ? active : null}
        className='sub-menu-categoria pt-3 pb-4'
        >
            Seguimientos de Envíos
            </p>  

        <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />   
        <p className=' py-3 sub-menu-categoria fw-bold' onClick={LogOut}>Cerrar Sesión</p>
    </div>
</>
  )
}
