import React from 'react'
import { Row, Col } from 'react-bootstrap';

export default function MisCompras() {
  return (
    <>
      <Row className='pt-5 '>
            <Col xs={12}>
                <h3 className='main-menu-title'>Mis Compras</h3>
            </Col>
        </Row>
        <Row className='pt-4'>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
                <Row>NOMBRE PRODUCTO</Row>
                <Row>Descripcion producto</Row>
                <Row>88.888$</Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col xs={4}>
                <p>Comprado el 13/23</p>
                </Col>
                <Col xs={4}>
                <p>Ver factura</p>
                </Col>
                <Col xs={4}>
                    <p>Ver envio</p>
                </Col>
                </Row>
            </Col>
        </Row>
    </>
  )
}
