import './viajes.css'
import { Row, Col, Button } from 'react-bootstrap';
import Destacada from './Destacada';
import CardViaje from './CardViaje';
import MiniCards from './MiniCards';

export default function MainBody() {
  return (
    <div>

        <Row className='viajes-container-msg'>
            <Col className='text-center' md={12}>
                <p className='viajes-p-msg py-3'>Tenés $888 disponibles para usar en tu próxima compra</p>
            </Col>
        </Row>
        <Row className='pt-3 pb-2 ps-0'>
            <Col className='p-0'>
            <p className='subtitle-viajesGrey'>Experiencia Destacada</p>
            </Col>
        </Row>
        <Row>
            <Col>
                <Destacada />
            </Col>
        </Row>
        <Row className='pt-5'>
            <Col md={3}>
                <Button bsPrefix='btn-viajes'>
                    circuitos
                </Button>
            </Col>
            <Col md={3}>
                <Button bsPrefix='btn-viajes'>
                    escapadas
                </Button>
            </Col>
            <Col md={3}>
                <Button bsPrefix='btn-viajes'>
                    salidas grupales
                </Button>
            </Col>
            <Col md={3}>
                <Button bsPrefix='btn-viajes'>
                    destinos nacionales
                </Button>
            </Col>
        </Row>
        <Row className='pt-5 pb-5'>
            <Col md={6}>
                <CardViaje />
            </Col>
            <Col md={6}>
                <CardViaje />
            </Col>
        </Row>
        <Row className='pt-5 pb-5'>
            <Col md={3}>
                <MiniCards />
            </Col>
            <Col md={3}>
                <MiniCards />
            </Col>
            <Col md={3}>
                <MiniCards />
            </Col>
            <Col md={3}>
                <MiniCards />
            </Col>
        </Row>
    </div>
  )
}
