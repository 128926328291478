import './credenciales.css';
import Iphone from '../../assets/images/mockuo-iphone-DePC-pass-ok.png';

import Card from '../../assets/images/mockuo-tarjeta-DePC-pass.png';


import BtnApp from '../BtnApp';

export default function Credenciales() {
  return (
    <div className='CredContain container'>
        <div className='TitleContain'>
            <div className='RightContain'>
                <h1 className="Title">La credencial que te <br/> acredita como estudiante</h1>
                <p className="SubTitle pt-4">Pagas con cualquier medio de pago y accedes a descuentos en cientos de comercios, además comprando en nuestra web te devolvemos el 2% para que sigas comprando.</p>
             </div>
        <div className='AppContain'>
            <p className='SubTitleApp'>¡Suscribite y empezá
a disfrutar!
            </p>
            <div className='ContainApp'>
               <BtnApp className="Google" subtitle="Disponible en" title='Google Play'/>
               <BtnApp className="Apple" subtitle="Descargar en" title='App Store'/>

            </div>
        </div>
        </div>
        <div className='ContainImgs'>
            <div className='ContainImgCard'>
                <img className='ImgCard' src={Card} alt="card" />
            </div>
            <div className='ContainImgIphone'>
                <img className='ImgIphone' src={Iphone} alt="iphone" />
            </div>
            
        </div>

    </div>
  )
}
