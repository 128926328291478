import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const stepFourValidationSchema = Yup.object({
    lstTarjetaTipo: Yup.string().required('Es obligatorio el ingreso del tipo de tarjeta'),
    txtTarjetaNombre: Yup.string().required('Es obligatorio el ingreso del nombre de la tarjeta'),
    txtTarjetaNumero: Yup.number().typeError('Ingresa solo numeros').required('Es obligatorio el ingreso del número de la tarjeta'),
    lstTarjetaVencimientoMes: Yup.string().required('Es obligatorio el ingreso del mes de vencimiento'),
    lstTarjetaVencimientoAño: Yup.string().required('Es obligatorio el ingreso del año de vencimiento'),
    txtTarjetaCVV: Yup.number().typeError('Ingresa solo numeros').required('Es obligatorio el ingreso del código de seguridad'),
    txtTarjetaDNI: Yup.number().typeError('Ingresa solo numeros').required('Es obligatorio el ingreso del DNI'),
   
    })

const StepFour = ( props) => {
    
    const handleShow = () => {
        document.getElementsByClassName('CardFront')[0].classList.add('CardFrontBackActive');
        document.getElementsByClassName('CardBack')[0].classList.add('CardBackBackActive');
    }
    const handleClose = () => {
        document.getElementsByClassName('CardFront')[0].classList.remove('CardFrontBackActive');
        document.getElementsByClassName('CardBack')[0].classList.remove('CardBackBackActive');
    };
    const handleSubmit = (values) => {
        window.scrollTo( 0, 0 );
      props.next(values, true);
    }
    return (
    <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={stepFourValidationSchema}
    >
     { ({values,errors,touched})=>(
      <Form>

        <Row>
          <Col className='text-center text-lg-start'>
          <h3 className='H3Pink30px'>Paso 4</h3>
          </Col>
        </Row>

        <Row className='pb-4'>
            <Col className='text-center text-lg-start'  xs={12} lg={6}>
                <p className='PFieldTitle pb-2'>¡Ya casi la credencial es tuya!</p>
                <p className='PGrey20px pb-3'>Completá los datos para la suscripción:</p>
                {
                errors.lstTarjetaTipo && touched.lstTarjetaTipo && 
                <div> <p className='PError'> {errors.lstTarjetaTipo} </p> </div> 
                }
                <Field 
                name="lstTarjetaTipo" 
                id="cc-type"  
                as="select"  
                className={"form-select pb-2 pt-2 " + (errors.lstTarjetaTipo && touched.lstTarjetaTipo ? 'is-invalid' : '')}
                onFocus={handleClose}
                >
                    <option disabled selected className='DisableOption' value='' >Tipo de tarjeta</option>
                    <option value="visa">Visa</option>
                    <option value="mastercard">Mastercard</option>
                    <option value="americanexpress">American Express</option>
                    <option value="dinersclub">Diners Club</option>
                </Field>
                <p className='PFieldHelpText pt-2 '> Obligatorio*</p>
            </Col>
        </Row>

        <Row>
            <Col  xs={12} lg={7}>

            <Row className='pb-4' >
                <Col xs={12} lg={9}>
                    {
                    errors.txtTarjetaNombre && touched.txtTarjetaNombre &&
                    <div> <p className='PError'> {errors.txtTarjetaNombre} </p> </div>
                    }
                    <Field 
                    name="txtTarjetaNombre" 
                    placeholder="Indicanos tu Nombre " 
                    id="cc-name" 
                    type="text" 
                    className={"form-control pb-2 pt-2 " + (errors.txtTarjetaNombre && touched.txtTarjetaNombre ? 'is-invalid' : '')}
                    onFocus={handleClose}
                    />
                    <p className='PFieldHelpText pt-2 '> Obligatorio*</p>
                </Col>
            </Row>
            <Row className='pb-4' >
                <Col xs={12} lg={9}>
                    {
                    errors.txtTarjetaNumero && touched.txtTarjetaNumero &&
                    <div> <p className='PError'> {errors.txtTarjetaNumero} </p> </div>
                    }
                    <Field 
                    name="txtTarjetaNumero" 
                    placeholder="Número de la tarjeta" 
                    id="cc-number" 
                    type="text" maxlength="16"
                    className={"form-control pb-2 pt-2 " + (errors.txtTarjetaNumero && touched.txtTarjetaNumero ? 'is-invalid' : '')}
                    onFocus={handleClose}
                    />
                    <p className='PFieldHelpText pt-2 '> Obligatorio*</p>
                </Col>
            </Row>

            <Row className='pb-4' >
                    {
                    errors.txtTarjetaCVV && touched.txtTarjetaCVV &&
                    <div> <p className='PError'> {errors.txtTarjetaCVV} </p> </div>
                    }
                    {
                    errors.lstTarjetaVencimientoAño && touched.lstTarjetaVencimientoAño &&
                    <div> <p className='PError'> {errors.lstTarjetaVencimientoAño} </p> </div>
                    }
                    {
                    errors.lstTarjetaVencimientoMes && touched.lstTarjetaVencimientoMes &&
                    <div> <p className='PError'> {errors.lstTarjetaVencimientoMes} </p> </div>
                    }
                <Col xs={3} lg={3}>
                   
                    <Field 
                    name="lstTarjetaVencimientoMes" 
                    as="select" 
                    id="cc-exp-month"  
                    className={"form-select pb-2 pt-2 " + (errors.lstTarjetaVencimientoMes && touched.lstTarjetaVencimientoMes ? 'is-invalid' : '')}
                    onFocus={handleClose}
                    >
                        <option disabled selected className='DisableOption' value='' >Mes</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </Field>
                    <p className='PFieldHelpText pt-2 '>Obligatorio*</p>
                </Col>
                <Col xs={3} lg={3}>
                    <Field 
                    name="lstTarjetaVencimientoAño" 
                    as="select" 
                    id="cc-exp-year"  
                    className={"form-select pb-2 pt-2 " + (errors.lstTarjetaVencimientoAño && touched.lstTarjetaVencimientoAño ? 'is-invalid' : '')}
                    onFocus={handleClose}
                    >
                        <option disabled selected className='DisableOption' value='' >Año</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                    </Field>
                    <p className='PFieldHelpText pt-2 '>Obligatorio*</p>
                </Col>
            
                <Col xs={6} lg={3}>
                    <Field 
                    name="txtTarjetaCVV" 
                    placeholder="CVV"
                    id="cc-csc" 
                    type="text" maxlength="16"
                    className={"form-control pb-2 pt-2 " + (errors.txtTarjetaCVV && touched.txtTarjetaCVV ? 'is-invalid' : '')}
                    onFocus={handleShow}
                    
                    />
                    <p className='PFieldHelpText pt-2'>Obligatorio*</p>
                </Col>
            </Row>

            <Row className='pb-4' >
                <Col xs={12} lg={9}>
                    {
                    errors.txtTarjetaDNI && touched.txtTarjetaDNI &&
                    <div> <p className='PError'> {errors.txtTarjetaDNI} </p> </div>
                    }
                    <Field 
                    name="txtTarjetaDNI" 
                    type="text" 
                    placeholder="DNI del Titular"
                    className={"form-control pb-2 pt-2 "  + (errors.txtTarjetaDNI && touched.txtTarjetaDNI ? 'is-invalid' : '')}
                    />
                    <p className='PFieldHelpText pt-2'> Obligatorio*</p>
                </Col>
                
            </Row>
            </Col>

            <Col className="ContainCardExample" sx={4}>
            <div className='CardExample CardBack ' >
                    <Row className='pb-4 pt-1' ><p className='PCardExample24px'> </p></Row>
                    <Row className='pb-4 pt-1 mt-5' style={{backgroundColor:"white"}}><p className='PGrey20px pe-4 text-end'> {values.txtTarjetaCVV ? values.txtTarjetaCVV : "CVV"}</p></Row>
                    <Row><p className='PCardExample24px'></p></Row>
                    <Row><p className='PCardExample24px'></p></Row>
                </div>
                <div  className='CardExample CardFront ' >
                    <Row className='pb-5 pt-3 ps-3' > <Col sx={12}><p className='PCardExample24px'>&#8205;  {values.lstTarjetaTipo} </p></Col></Row>
                    <Row className='ps-3' > <Col sx={12}><p className='PCardExample24px '>&#8205; {values.txtTarjetaNumero ? values.txtTarjetaNumero.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() :"8888 8888 8888 8888" }</p></Col></Row>
                    <Row className='ps-3' > <Col sx={12}><p className='PCardExample24px pt-1' >&#8205; {values.txtTarjetaNombre ? values.txtTarjetaNombre : "Nombre"}</p></Col></Row>
                    <Row className='ps-3 pb-2' > <Col sx={12}> <p className='PCardExample24px'>&#8205; {values.lstTarjetaVencimientoMes ? values.lstTarjetaVencimientoMes  : "mm" }/{values.lstTarjetaVencimientoAño ? values.lstTarjetaVencimientoAño : "aa"}</p></Col></Row>
                </div>
                
            </Col>
        
        </Row>
        

        <Row className='pt-5'>
            <Col>
                <Button bsPrefix='btnPrev' type='button' onClick={()=> props.prev(values)} >&lt; Volver</Button>
            </Col>

            <Col className='text-center'>
                <Button bsPrefix='btnNext' type='submit'>Siguiente</Button>
            </Col>
      </Row>
      </Form>
     )}
    </Formik>
    )}

    export default StepFour;