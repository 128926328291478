import './descubri.css'
import {  Row, Col } from "react-bootstrap"
import  CardDescubri  from "../CardDescubri"
import Cultura from "../../assets/images/descubrir_cultura.png"
import Bebida from "../../assets/images/descubrir_drink.png"
import Explorar from "../../assets/images/descubrir_travel.png"
import Musica from "../../assets/images/descubrir_musica.png"
export default function Descubri() {
  return (
    <div className='container'>
        <Row className='justify-content-center text-center py-5'>
            <Col md={12} className="text-center align-middle ">
                <h2 className='H2Pink36px'>¡Descubrí todo lo que tenemos para vos!</h2>
                </Col>
        </Row>
        <Row className='justify-content-center  '>

            <Col xs={6} xl={3}  className="my-4 mx-xl-0 text-center" >

                <CardDescubri
                    Src={Cultura}
                    Title="Cultura"
                    TextButton="Descubrilo"
                />

            </Col>

            <Col xs={6}  xl={3} className="my-4 mx-xl-0 text-center" >

                <CardDescubri
                    Src={Explorar}
                    Title="Viajes"
                    TextButton="Descubrilo"
                />

            </Col>

            <Col xs={6}  xl={3} className="my-4 mx-xl-0 text-center" >

                <CardDescubri
                    Src={Bebida}
                    Title="Descuentos"
                    TextButton="Descubrilo"
                />

            </Col>

            <Col xs={6}  xl={3} className="my-4 mx-xl-0 text-center" >

                <CardDescubri
                    Src={Musica}
                    Title="MARKETPLACE"
                    TextButton="Descubrilo"
                />

            </Col>

        </Row>

    </div>
  )
}
