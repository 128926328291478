import '../assets/css/descuentos.css';
import DescuentoHero from '../components/DescuentoHero';
import DescuentoBody from '../components/DescuentoBody';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


export default function Descuentos(props) {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = props.title + " | Credencial estudiantil";
  }, [location]);
  return (
    <>
    <DescuentoHero/>
    <section className='SectionDescuento1'>
      <DescuentoBody/> 
    </section>
    </>
  )
}
