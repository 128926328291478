import { Row, Col } from 'react-bootstrap';
import './cultura.css';
import Card from './Card';
import CardCircuito from './CardCircuito';
import { useState, useEffect } from 'react';
import * as Service from '../../services';

export default function CarruselLittle({rubro, circuito}) {
    const [nota, setNotas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            if(!circuito)
            { 
                const data = await Service.getNotaRubro(rubro.idrubrocultural);
                setNotas(data);
            } else{
                const data = await Service.getData('/getCircuitos');
                setNotas(data);
            }

            }
            fetchData();
        }
        , []);

    if(circuito){
        return(
            <div className='p-0'>
                    <Row className='pt-3 mx-0 '>
                        <div className='carrusel-contain-cultura ScrollCustom pb-3 px-0 mx-0'>
                            {nota.map((item, index) => {
                                return <CardCircuito key={index} content={item} />
                                })
                            }
                        </div>
                    </Row>
            </div>

        )
    }

    if(nota.length > 0)
    {
        return (
            <div className='p-0'>
                <Row>
                    <Col lg={12}>
                        <p style={{fontWeight:'500',fontSize:'16px'}} className='cultura-card-text'>{rubro.nombre}</p>
                    </Col>
                </Row>
                <Row className='pt-3 mx-0 '>
                    <div className='carrusel-contain-cultura ScrollCustom pb-3 px-0 mx-0'>
                        {nota.map((item, index) => {
                            return <Card key={index} content={item} isLittle />
                            })
                        }
                    </div>
                </Row>
            </div>
        )
    }
}
