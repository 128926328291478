import { Col, Row, Form } from 'react-bootstrap';
import LeftMenu from './LeftMenu';
import CardMarket from './CardMarket';
import { useState, useEffect } from 'react';
import * as Services from '../../services';

export default function Marketplace() {
    const [data, setData] = useState([]);
    const [filtro, setFiltro] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const getData = async () => {
            const data = await Services.getData('/getProductos');
            setData(data);
            console.log(data);
        }
        getData();
        let parent = document.getElementById('todos');
        if(parent)
        { 
          let children = parent.getElementsByClassName("producto");
          let cantidad = children.length;
          setCount(cantidad);
        }
    } , [filtro]);

    return (
    <Row className='pt-4 me-5'>
        <Col md={3} className='px-5'>
            <LeftMenu filtro={filtro} setFiltro={setFiltro} />
        </Col>
        <Col md={9}>
            <Row className='viajes-container-msg'>
                <Col className='text-center' md={12}>
                    {
                    localStorage.getItem('idbeneficiario') ? <p className='viajes-p-msg py-3'>Tenés $888 disponibles para usar en tu próxima compra</p> 
                    : 
                    <p className='viajes-p-msg py-3'>Inicia sesion para ver tu saldo</p>
                    }
                </Col>
            </Row>
            {filtro.length === 0&&<><Row className='pt-5'>
                <Col md={12}>
                    <p className='subtitle-marketplace'>
                        Productos Destacados
                    </p>
                </Col>
            </Row>
            <Row className='pt-3'>
                {
                    data.map((item, index) => {
                        return (
                            <Col  md={3} key={index}>
                                <CardMarket content={item} destacado/>
                            </Col>
                        )
                    })
                }
            </Row>
            </>
            }
            <Row className='pt-5'>
                <Col md={7}>
                    <p className='subtitle-marketplace'>
                       {filtro.length > 0 ? count : data.length} resultados ordenados por Precio (bajo a alto)
                    </p>
                </Col>
                <Col md={5}>
                    <Form.Select bsPrefix='select-custom' aria-label="Floating label select example">
                        <option>Open this select menu</option>
                        <option value="1">Precio (bajo a alto)</option>
                        <option value="2">Precio (Alto a bajo)</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row id='todos' className='pt-3'>
                {
                    data.map((item, index) => {

                        if (filtro.length > 0)
                        {   
                            if(filtro.includes(item.categoria))
                            {
                                console.log('hola?', item.nombre)
                                return (
                                    <Col className='mt-3 producto' md={3} key={index}>
                                        <CardMarket content={item} />
                                    </Col>
                                )
                            } else return null;
                        }

                        return (
                            <Col className='mt-3 producto' md={3} key={index}>
                                <CardMarket content={item} title={item.nombre} price={item.precio_pesos} oldPrice={item.precio} discount={item.descuento} image={item.imagen} />
                            </Col>
                        )


                    })
                    
                }
                
            </Row>
        </Col>
    </Row>
  )
}


