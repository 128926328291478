
import Credenciales from '../components/Credenciales';
import Carrusel from '../components/Carrusel';
import MarketPlace from '../components/MarketPlaceCarrusel';
import CashBack from '../components/Cashback';
import Subscribite from '../components/Subscribite';
import Descubri from '../components/Descubri';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import '../assets/css/home.css';

export default function Home({props}) {
  let location = useLocation();
  useEffect(() => {
    document.title = "Credencial estudiantil pensada para vos";
  
  }, [location]);
  return (
    <div>
          
          <section className='SectionHome1'>
            <Credenciales />
            <Carrusel />
          </section>

          <section className='SectionHome2'>
            <MarketPlace /> 
            <CashBack/>
          </section>
          
          <Subscribite />
          <Descubri />
        </div>
  )
}
