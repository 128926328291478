import '../assets/css/descuento.css'
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Service from '../services';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CardDescuento from '../components/Descuento';
import CarouselDescuentos from '../components/DescuentoBody/CarouselDescuentos';
import Cupon from '../components/Descuento/Cupon';
import Divider from '../components/Divider';

export default function Descuento({props}) {
  const [idBeneficiario, setIdBeneficiario] = useState();
  const [showQrApp, setShowQrApp] = useState(false);
  const [qr, setQr] = useState([]);
  const [codQR, setCodQR] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [showCredencial, setShowCredencial] = useState(false);
  const [showCupon, setShowCupon] = useState(false);
  const [showCuponError, setShowCuponError] = useState(false);
  const [descuento, setDescuento] = useState([]);
  const [descuentosRubros, setDescuentosRubros] = useState([]);

  let location = useLocation();
  let { id } =  useParams();

  useEffect(() => {
    if (localStorage.getItem('idbeneficiario')) {
      setIdBeneficiario(localStorage.getItem('idbeneficiario'));
    } else {
      setIdBeneficiario('');
    }
    setShowCredencial(false);
    setShowBtn(false)
    setShowQR(false)
    setShowQrApp(false)
    window.scrollTo(0, 0);
    const getDescuento = async () => {
      const data = await Service.getDescuentosPorId(id);
      const qr = await Service.getQR(1219,data.idcupon);
      setQr(qr);
      if(qr.code ===400){
        setCodQR(qr.codQR);
      }
    
      setDescuento(data);
      handleShowButton(data);
      document.title = data.nombre_comercio + " | Credencial estudiantil";
    }
    const getDescuentosPorRubro = async () => {
      const data = await Service.getDescuentosPorRubro();
      setDescuentosRubros(data);
  }

    getDescuento();
    getDescuentosPorRubro();
   
  }, [location]);

  const handleShowButton = (data) => {
     if ( data.incluye_cod_qr === 1 && data.descuento_qr !== 1)
    {
      setShowQR(true);
    }
    else if (data.descuento_qr === 1)
    {
      setShowQrApp(true);
    }
   else if(data.tipo_compra === 'Online' || data.tipo_compra === 'Online/Presencial')
   {
    if(data.cod_promocional && data.cod_promocional !=='No')
    {
      setShowBtn(true);
    }
   }
   else if (data.tipo_compra === 'Presencial')
   {
    setShowCredencial(true);
   }
  };

  const handleClose = () => {
    setShowCuponError(false);
    setShowCupon(false);
  }
  const handleShowCupon = () => {
    if(idBeneficiario !== ''){
      setShowCupon(true);
    }
    else{
      setShowCuponError(true);
    }
   
  }
  
  const handleShowQR = async () =>{
    if(idBeneficiario !== ''){
    
    if (qr.code === 200) {
      const data = await Service.postVisitaQR(1219,descuento.idcupon,descuento.idComercio);
      setCodQR(data.codigoQR);
      
    }
    setShowCupon(true);
    }
    else{
      setShowCuponError(true);
    }
  }

  return (
    <Container className='mx-lg-5 px-lg-5 mx-xl-auto px-xl-auto'>
      <Container className='mx-xl-5'>
      <Row className='py-4'>
      <p className='PHrefDescuentos'><span className='text-decoration-underline'><Link style={{color:'var(--font-grey)'}} to='/descuentos'>Descuentos</Link></span> &gt; {descuento.nombre_comercio}</p>
      </Row>
      <CardDescuento content={descuento} />
      {showCuponError && <Cupon modal={showCuponError} isError={true} close={handleClose} />}
     
        {showBtn ? <>
          {
            showCupon && 
                <Cupon modal={showCupon}  cupon={descuento} close={handleClose}  />
          }
          
          <Row className='justify-content-center py-4'>
            <Col className='text-center'>
            <Button onClick={handleShowCupon} bsPrefix='btnQuieroDescuento'>
              ¡Quiero mi beneficio!
            </Button>
            </Col>
          </Row>
          </>
        :null
        }

        {showCredencial ? <>
        {
          showCupon && 
              <Cupon modal={showCupon}  cupon={"Presentá tu credencial física u online en el local físico"} close={handleClose} isMsg={true}  />
        }
        
        <Row className='justify-content-center py-4'>
          <Col className='text-center'>
          <Button onClick={handleShowCupon} bsPrefix='btnQuieroDescuento'>
            ¡Quiero mi beneficio!
          </Button>
          </Col>
        </Row>
        </>
        : 
        null
        }

        {showQR ? <>
          {
            showCupon && 
                <Cupon modal={showCupon}  cupon={codQR} close={handleClose} isQr={true} />
          }
       
        <Row className='justify-content-center py-4'>
          <Col className='text-center'>
            <Button onClick={handleShowQR} bsPrefix='btnQuieroDescuento'>
              ¡Quiero mi beneficio!
              </Button>
              </Col>
              </Row> 
              </> :
              null
        }
        {showQrApp ? <>
          {
            showCupon &&
                <Cupon modal={showCupon}  cupon={'Descarga la App'} close={handleClose} isMsg={true} />
          }
        <Row className='justify-content-center py-4'>
          <Col className='text-center'>
            <Button onClick={handleShowCupon} bsPrefix='btnQuieroDescuento'>
              ¡Quiero mi beneficio!
              </Button>
              </Col>
              </Row>
              </> 
              :
              null
        }
    {
    descuento.descripcion ?<>  
      <Row >
        <h3 className='H3Subtitle'>Descripción del beneficio</h3>
      </Row>
      <Row className='py-3'>
        <p className='PDescripcionDescuento'>{descuento.descripcion}</p>
      </Row>
      </> 
      : null
      }
      <Row className='py-3'>
        <h3 className='H3Subtitle'>Legales</h3>
      </Row>
      <Row className='py-3'>
        <p className='PDescripcionDescuento'>{descuento.legales}</p>
      </Row>
     {/* { descuento.fecha_vencimiento ?<Row className='py-3'>
        <p className='PDescripcionDescuento' style={{fontWeight:'700'}}>Valido hasta {descuento.fecha_vencimiento.slice(0,10)}</p>
      </Row> 
      : null
      } */}
      <Row className='py-3'>
        <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />
      </Row>
       <Row className=' mt-5'>
        { 
        descuentosRubros.map((rubros,index) => {
           if(rubros.rubro === descuento.nombre_rubro)
           {
            return(
              <CarouselDescuentos  style={'white'} descuentos={rubros.beneficios} title='Otros descuentos que te pueden interesar' />
            )
            }
          })
          } 
      </Row> 
      </Container>
    </Container>
  )
}
