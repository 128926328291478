
import FormCredencial from '../components/FormCredencial';
import '../assets/css/suscripcion.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Suscripcion(props) {
  let location = useLocation();
  useEffect(() => {
    document.title = props.title + " | Credencial estudiantil";
  }, [location]);
  return (
    <div>
        <section className='SectionSub1'>
            <FormCredencial />
        </section>

    </div>
  )
}
