import './descuentohero.css'
import { Row, Col , Button, Container } from 'react-bootstrap';
import Divider from '../Divider';


export default function DescuentoCard({ img,descuento}) {
  return (
    <Row >
                <Col  xs={12} md={7}>
                    <img  className="ImgHeroDescuento" src={img} alt={descuento.promocion}/>
                </Col>
                <Col className='container pe-5' xs={12} md={5}>
                  <Container className='d-none d-md-block pe-5'>
                    <Row className='py-4 text-center justify-content-center'>
                        <img className='LogoHeroDescuento pb-3' src={`https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/${descuento.logo}`} alt={descuento.promocion} />
                        <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />
                    </Row>
                    <div style={{height:'125px'}}>
                    <Row>
                        <h2 className='H2Pink36px'>{descuento.promocion}</h2>
                    </Row>
                    <Row>
                        <p className='PGrey20px' style={{fontWeight:'700'}}>{descuento.descripcion}</p>
                    </Row>
                    </div>
                    <Row className='pt-3 ps-3'>
                      <a href={descuento.link} >
                        <Button bsPrefix='btnDescuento'>OBTENER DESCUENTO </Button>
                      </a>
                    </Row>
                  </Container>



                  <Container className='d-md-none pb-4 ContainMobilHeight'>
                    <Row className='pt-2' >
                      <Col xs={4} className="align-middle pt-2">
                        <img className='LogoHeroDescuento '
                        src={`https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/${descuento.logo}`} 
                        alt={descuento.promocion} />
                      </Col>
                      <Col xs={1}>
                      <Divider style={{backgroundColor:"var(--grey)"}} />
                      </Col>
                      <Col xs={7} style={{height:'120px'}}>
                        <h2 className='H2Pink36px'>{descuento.promocion}</h2>
                        <p className='PGrey20px overflow-hidden-elipsis' style={{fontWeight:'700'}}>{descuento.descripcion}</p>
                      </Col>
                    </Row>
                    <Row className='pt-3' >
                      <a href={descuento.link}  >
                        <Button bsPrefix='btnDescuento'>OBTENER DESCUENTO </Button>
                      </a>
                    </Row>
                    </Container>
                </Col>
            </Row>
  )
}
