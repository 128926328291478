import { Row, Col } from 'react-bootstrap';
import Card from './Card';
import Calendar from '../../assets/images/icon-calendar.svg';
import Divider from '../Divider';
import './cultura.css';

export default function Carrusel({subtitulo, titulo, content ,icon}) {
  return (<>
   
    <Row className='m-0 p-0'>
        <Col xs={4} md={1} className='p-0 text-start'>
            <img src={icon} alt='...' />
        </Col>
        <Col xs={8}  lg={subtitulo ? 5 : 10} className=' my-auto me-lg-3 p-0 '>
            <h2 className='subtitle-cultura' >{titulo}</h2>
        </Col>
        { subtitulo && <Col  className='my-auto d-none d-lg-block  p-0'>
            <Row className='p-0'>
                <Col md={1} className='p-0'>
                <Divider style={{ height:'50px',backgroundColor:'var(--grey)',padding:'0px'}} />
                </Col>
                <Col className='pe-2'>
                <p className='carrusel-subtitle-cultura' >{subtitulo}</p>
                </Col>
            </Row>
           
            
        </Col>
        }
    </Row>
    <Row className='container-lg mx-0 px-0'>
             <Row className='pt-5 '>
        <div className='carrusel-contain-cultura ScrollCustom px-0 pb-3'>
            {content.map((item, index) => {
                return <Card key={index} content={item} />
            }
            )}
        </div>
    </Row>
    </Row>
    </>
  )
}
