import './subscribite.css';
import {  Row, Col } from "react-bootstrap"
import Divider from '../Divider';
import Computadora from "../../assets/images/computadora-icon.svg"
import Celular from "../../assets/images/celular-logo.svg"
import Smile from "../../assets/images/smile-icon.svg"
import CardSubscribite from '../CardSubscribite';
export default function Subscribite() {
  return (
    <div className='container pt-5'>
    <Row className='pb-5'>
        <Col className="text-center">
            <h2 className="SubscribiteTitle">
            ¡Suscribite ahora y empezá a disfrutar!
            </h2>
        </Col>
    </Row>
    <Row>
        <Col xs={"12"} lg={"4"} className="p-2 text-center">

            <CardSubscribite
                Src={Computadora}
                NroTitle={1}
                Title="Pedi tu credencial"
                Desc="Los primeros 3 meses es GRATIS para estudiantes de DePC y luego $800 por mes. "
                ButtonText="Solicitá"
            />

        </Col>
        <Col xs={"12"} lg={"4"} className="  p-2  text-center">

            <CardSubscribite
                Src={Celular}
                NroTitle={2}
                Title="Descargá la App"
                Desc="Empezá a disfrutar los descuentos en cientos de comercios de todo el país."
                ButtonText="Descargá"
            />
        </Col>
        <Col xs={"12"} lg={"4"} className=" p-2 text-center">
            <CardSubscribite
                Src={Smile}
                NroTitle={3}
                Title="Comenzá a disfrutar"
                Desc="Con tu credencial estudiantil ya podés acceder a tus descuentos y beneficios."
                ButtonText="+ Beneficios"
            />
        </Col>
    </Row>
    <Row className='justify-content-center py-5 '>
        <Col xs={"10"}>
        <Divider isHorizontal  style={{backgroundColor:"var(--grey)", width:"100%"}} />
        </Col>
    </Row>
    <Row className='justify-content-center py-5 '>
        <Col xs={"12"} md={"10"} className="text-center ">
            <p className='DescSub'>
            <span style={{color:"red",fontWeight:"700"}}>DePC</span> Es la credencial que te acredita como estudiante de DePC o de un centro educativo, sin límite de edad, dándote beneficios y descuentos exclusivos. Podrás acceder gratis o con descuentos preferenciales a:
               <span style={{color:"var(--pink)",fontWeight:"700"}}> Museos, Teatros, Centros Culturales y descuentos en comercios.</span>
            </p>
        </Col>
    </Row>
    <Row className='justify-content-center py-5 '>
        <Col  xs={"10"} >
        <Divider isHorizontal  style={{backgroundColor:"var(--grey)", width:"100%"}} />
        </Col>
    </Row>
    </div>
  )
}
