import './cardmarket.css';
import { Button } from 'react-bootstrap';
export default function CardMarket({title, price, image, oldPrice, discount}) {
  return (
    <div className='ContainCard'>
        <div className="ContainImgMarket">
            <img className='ImgMarket '  src={image} alt={title}/>
        </div>
        <div className="">
            <p className="TitleMarket">{title}</p>
            <div className='ContainOldPrice' style={{textAlign:'center'}}>
            <p className="OldPriceMarket">$ {oldPrice}</p>
            <p className="DiscountMarket">{discount}% OFF</p>
            </div>
            <p className="PriceMarket">$ {price}</p>
            </div>
        <div className="ContainButtonMarket">
            <Button bsPrefix='btnBuy' variant="primary">Comprar</Button>
            </div>

    </div>
  )
}
