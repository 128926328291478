import { Row, Col, Container, InputGroup, Form } from 'react-bootstrap'
import { FaTimes, FaSistrix } from "react-icons/fa";
import { BsSliders } from "react-icons/bs";
import AccordionDescuento from './AccordionDescuento';
import CarouselDescuentos from './CarouselDescuentos';
import Divider from '../Divider'
import './descuentobody.css'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import { useEffect, useState } from 'react';
import  CardDescuento  from './CardDescuento';
import * as Service from '../../services';
import Map from './Map';


export default  function  DescuentoBody() {
    const [showMap, setShowMap] = useState(false);
    const [busqueda, setBusqueda] = useState('')
    const [filtro, setFiltro] = useState([]);
    const [rubros, setRubros] = useState([]);
    const [descuentosRubros, setDescuentosRubros] = useState([]);

    const handleDeleteFiltro = (nombre) => {
        setFiltro(filtro => filtro.filter(item => item !== nombre));
    };
    const handleAdd = (nombre) => {
        if(!filtro.includes(nombre)){
        setFiltro(filtro => [...filtro, nombre]);
        }
      };
    useEffect(() => {
        const getDescuentos = async () => {
        const data = await Service.getRubros();
        setRubros(data);
        }
        const getDescuentosPorRubro = async () => {
            const data = await Service.getDescuentosPorRubro();
            setDescuentosRubros(data);
        }
        getDescuentos();
        getDescuentosPorRubro();
    }, []);
  return (
    <Container fluid className='py-3'>

    <Row className=' text-center justify-content-center d-none d-md-block'>
       <div className='d-flex flex-row justify-content-center' >
            {rubros.map((filtro,index) => {
                return (
              
                <div key={index} className=' d-flex align-items-center'>
                    <p key={index} onClick={()=>handleAdd(filtro.nombre)} className='PFiltro px-2' >{filtro.nombre}</p>
                    {rubros.length - 1 === index ? null : <Divider style={{backgroundColor:"var(--red)", height:"16px"}} /> }
                    
                </div>
               
                )})} 
          
                
        </div>
     
    </Row>

    <Row className='pt-4 '>
        <Col className='d-none d-md-block'  xs={0} md={3}>
            <div className='BodyFilters container '>
                <Row className=' justify-content-center py-2'>
                    <p 
                    style={{cursor:'pointer'}}
                    className='PReinciarFiltros text-uppercase' 
                    onClick={()=>setFiltro('') }
                    >
                        Reiniciar filtros
                    </p>
                </Row>
                <Row>
                    {filtro ? 
                    filtro.map((filtro,index) => {
                        return (
                    <div className='ContainFilters m-1'>
                    <p className='PFiltroGrey pe-2'>{filtro}</p>
                    <FaTimes style={{cursor:'pointer'}} onClick={()=>handleDeleteFiltro(filtro) } color='var(--grey)' fontSize='11px'/>
                    </div> 
                        )
                    })
                    : null}
                </Row>
                <Row className='py-4'>
                    <AccordionDescuento divider filtro={filtro}  setFiltro={setFiltro} title='Rubro' content={rubros} />
                </Row>
            </div>
        </Col>


        <Col className='' xs={12} md={9}>
            <Row className='ContainSearchDescuentos  ms-1 ms-md-0 container-md  py-3 align-items-center'>
                <Col xs={3} className='d-md-none'>
                <div className='d-flex flex-row  align-items-center'>
                        <p className='PDescuentosRed pe-1'>Filtros</p>
                        <BsSliders color='var(--red)' fontSize='20px'/>
                </div>
                </Col>
                <Col xs={5} md={6} style={{height:'32px'}} className='d-flex flex-row align-items-center '>
                    <p className='pe-3  PDescuentosBuscar d-none d-md-block'>Buscar</p>
                    <InputGroup>
                        <Form.Control
                            style={{borderRight:'none'}}
                            placeholder="Buscar"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange = {(e)=>{
                                setBusqueda(e.target.value)
                            }}
                            />
                        
                        <InputGroup.Text 
                            className='d-none d-md-block'
                            style={{backgroundColor:'white',borderLeft:'none'}} 
                            id="basic-addon2">
                            <FaSistrix color='var(--grey)' fontSize='17px'/>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col xs={4} md={6} className='text-end align-middle'>
                    <div className='d-flex flex-row justify-content-end align-items-center'>
                        <p onClick={()=>setShowMap(!showMap)} className='PDescuentosRed cursor-pointer'>
                             Cerca mio
                        </p>
                        <HiOutlineLocationMarker color='var(--red)' fontSize='20px'/>
                    </div>
                </Col>
            </Row>
           {showMap && <Row className='container-md py-3'>
                <Map busqueda={busqueda} filters={filtro} />
            </Row>
            }

            {filtro.length || busqueda ?
            <Row>
                {descuentosRubros.map((rubros) => {  
                    if(filtro.includes(rubros.rubro) && filtro.length){
                    return (
                        rubros.beneficios.map((descuento,index) => {
                            if (busqueda){
                                if (descuento.nombre_comercio.toLowerCase().includes(busqueda.toLowerCase())){
                                    return (
                                        <Col md={6} lg={4} xl={3} key={index} className='pt-3 d-flex flex-row justify-content-center align-items-center'>
                                            <CardDescuento 
                                                id={descuento.id}
                                                nombre={descuento.nombre_comercio}
                                                src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/'+descuento.imagen_ilustrativa}
                                            />
                                        </Col>
                                    )
                                }
                            }else{

                            return (
                                <Col md={6} lg={4} xl={3}  key={index} className='pt-3 d-flex flex-row justify-content-center align-items-center'>
                                    <CardDescuento 
                                    id={descuento.id} 
                                    nombre={descuento.nombre_comercio}
                                    src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/'+descuento.imagen_ilustrativa}
                                    />
                                </Col>
                                    )
                                }
                            })
                        )
                    }

                    else if(busqueda && !filtro.length){
                        return (
                            rubros.beneficios.map((descuento,index) => {
                                if (descuento.nombre_comercio.toLowerCase().includes(busqueda.toLowerCase())){
                                    return (
                                        <Col md={6} lg={4} xl={3}  key={index} className='pt-3 d-flex flex-row justify-content-center align-items-center'>
                                            <CardDescuento 
                                                 id={descuento.id}
                                                nombre={descuento.nombre_comercio}
                                                src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/'+descuento.imagen_ilustrativa}
                                            />
                                        </Col>
                                    )
                                }
                            })
                        )
                    }
                })
                }

            </Row>
            :
            descuentosRubros.map((descuento,index) => {
                return (
                <Row className='container-md'>
                    <CarouselDescuentos key={index} descuentos={descuento.beneficios} title={descuento.rubro} />
                </Row>
                )
            })
            
            }

        </Col>
    </Row>
    </Container>
  )
}
