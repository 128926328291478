import './viajes.css'
import { Row, Col, Button } from 'react-bootstrap';
import Divider from '../Divider';
import Grecia from '../../assets/images/viajes_ny.png';
export default function CardViaje() {
  return (
    <div className='container-destacada'>
        <Row>
            <Col md={12} style={{height:'231px'}}>
                <img style={{borderTopRightRadius:'10px',borderBottomLeftRadius:'0px'}} src={Grecia} alt='...' className='destacada-img' />
            </Col>
        </Row>
        <Row className='container pt-3 pb-3' >
            <Col className='text-center' md={12}>
                <h3 style={{fontSize:'20px'}} className='destacada-title'>Grecia y Turquía</h3>
            </Col>
            <Col md={12}>
                <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
            </Col>
            <Col className='pt-3 text-center' md={12}>
                <p style={{fontSize:'10px'}} className='destacada-descripcion'><span style={{fontWeight:'700'}}> Itinerario: </span> Paquete Turístico de 16 días 15 noches Visitando Frankfurt, Heidelberg, Munich, Zurich, Interlaken, Montreux, Milan.</p>
            </Col>
            <Col className='pt-3 ' md={12}>
                <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
            </Col>
            <Col className='pt-3 text-center' md={12}>
                <p className='destacada-descripcion'> desde  
                    <span style={{fontWeight:'700'}}> ARS 55.096 </span>
                    <span className='destacada-oldPrice'>$888888 </span> 
                    <span className='destacada-descuento ms-3'> 12% OFF</span>
                </p>
            </Col>
            <Col className='pt-4' md={7}>
                <p className='destacada-link'>¿Cómo comprar?</p>
                <p className='destacada-minDescripcion'>Comercializado por: shareargentina.com.ar</p>
            </Col>
            <Col className='text-end pt-3 m-auto' md={5}>
                <Button bsPrefix='btn-destacada'>
                     Ver mas
                </Button>
            </Col>
        </Row>

    </div>
  )
}
