import Facebook from '../../assets/images/facebook-logo.svg'
import Linkedin from '../../assets/images/linkedin-logo.svg'
import Instagram from '../../assets/images/instagram-logo.svg'
import { FaInstagram, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import QRLegales from '../../assets/images/Imagen 9.png';
import Divider from '../Divider';
import BtnApp from '../BtnApp';


import './footerinfo.css'

export default function FooterInfo() {
  return (
    <div className='FooterContainInfo'>
       
        <div className='FooterInfoSocial'>
            <p className='TitleFooter'>Seguinos!</p>
            <div className='FooterInfoSocialIcon'>
                <a href='https://www.facebook.com/depcsuite/' target='_blank' rel="noopener noreferrer">
                    <FaFacebook color="white" fontSize="33px" />
                </a>
                <a href='https://www.linkedin.com/company/depcsuite/' target='_blank' rel="noopener noreferrer">
                    <FaLinkedin color="white" fontSize="33px" />
                </a>
                <a href='https://www.instagram.com/depcsuite/' target='_blank' rel="noopener noreferrer">
                    <FaInstagram color="white" fontSize="33px" />
                </a>
                <a href='https://twitter.com/depcsuite' >
                    <FaTwitter color="white" fontSize="33px" target='_blank' rel="noopener noreferrer" />
                </a>
                {/* <img src={Facebook} alt="facebook" />
                <img src={Linkedin} alt="linkedin" />
                <img src={Instagram} alt="instagram" /> */}
            </div>
        </div>
        <div  className='DivideMobilLight'>
        <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"100%"}} />
        </div>
        <div className='FooterInfoContact'>
            <p className='TitleFooter'>Contacto</p>
            <p className='SubtitleFooter'> Por consultas y/o reclamos podés escribirnos al WhatsApp +54 11 6284-5700 o al mail credencial@depcsuite.com </p>
            <div className='FooterInfoBtn'>
            <BtnApp className="Google" subtitle="Disponible en" title='Google Play'/>
            <BtnApp className="Apple" subtitle="Descargar en" title='App Store'/>
            </div>
        </div>
        <div  className='DivideMobilLight'>
        <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"100%"}} />
        </div>
        <div className='FooterInfoHome'>
            <p className='TitleFooter'>Home</p>
            <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"197px"}} />
            <p className='List'> Descuentos</p>
            <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"197px"}} />
            <p className='List'> Cultura</p>
            <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"197px"}} />
            <p className='List'> Viajes</p>
            <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"197px"}} />
            <p className='List'> MarketPlace</p>
             <div className='Display-none-mobil'>
            <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"197px"}} />
            </div>
        </div>
        <div  className='DivideMobilLight'>
        <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"100%"}} />
        </div>
        <div className='ContainLegales'>
            <p className='TitleFooter'>Legales</p>
            <p className='SubtitleFooter'>Términos y condiciones</p>
            <a href="http://qr.afip.gob.ar/?qr=y8gIoIFtVdLWtCgPS3s05g,," target="_F960AFIPInfo">
			    <img className="fiscal img-responsive" src="https://depcsuite.com/wp-content/themes/depcsuite/images/afip.png" alt="Data Fiscal" width="45" height="61"/>
			</a>
        </div>
    </div>
  )
}
