import { Button} from 'react-bootstrap';
import './btnapp.css';

export default function BtnApp({className, subtitle, title}) {
  return (
    <Button bsPrefix={'btnApp ' + className } >
    <div className='BtnContain' >
        <div className={"icon"} >
         
        </div>
        <div className='TextContain'>
            <p className='subtitle'>{subtitle} </p>
            <p className='title'>{title}</p>
        </div>
    </div>
    </Button>
  )
}
