import './viajes.css'
import LeftMenu from './LeftMenu';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Divider from '../Divider';
import MainBody from './MainBody';
export default function BodyViajes() {
  return (
    <div>
        <Row className='pt-5'>
            <Col md={3}>
                <LeftMenu />
            </Col>


            <Col md={8}>
                <MainBody />
            </Col>
        </Row>

    </div>
  )
}
