import Body from '../components/Viajes';


export default function Viajes() {
  return (
    <div style={{backgroundColor:'var(--light-grey)'}}>
    <Body />
    </div>
  )
}
