import { GoogleMap, useLoadScript, OverlayView, Marker } from "@react-google-maps/api";
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from "react";
import * as Service from "../../services";
import './cultura.css';
import AccordionMap from "./AccordionMap";
import MarkerCustom from "./MarkerCustom";

export default function Map({busqueda,filtro,filtroDia}) {
    const [selected, setSelected] = useState(null);
    const [notasMap, setNotasMap] = useState([]);
    const [center, setCenter] = useState({
        lat: -34.6037,
        lng: -58.3816,
        });

 useEffect(() => {
    const fetchData = async () => {
        const data = await Service.getData('/getProvinciasNotas');
        setNotasMap(data);
    }
    fetchData();
    navigator.geolocation.getCurrentPosition(function(position) {
        setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        });
    }    
    );
    }, []);



    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ,
    })
    if (!isLoaded) {
        return <div>Loading...</div>;
    }else {

        return(
            <GoogleMap
                id="map"
                options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                }}
                mapContainerStyle={{
                    height: "70vh",
                    width: "100%",
                }}
                zoom={13}
                center={center}
            >
             
                <div className="overlay-map container-locations d-none d-lg-block">
                    <div className="container  pt-3 px-3">
                        <Row >
                                {notasMap && notasMap.map((item, index) => {
                                    return <AccordionMap 
                                    select={selected} 
                                    setSelect={setSelected} 
                                    setCenter={setCenter} 
                                    key={index} 
                                    item={item} 
                                    busqueda={busqueda}
                                    filtro={filtro}
                                    filtroDia={filtroDia}
                                    />
                                })
                                }
                        </Row>
                    </div>
                </div>

                {notasMap && notasMap.map((item, index) => {
                    return(
                    item.notas.map((nota, index) => {
                        if(nota.latitud !== nota.longitud){

                            const desde = new Date(nota.fecha_desde);
                            const hasta = new Date(nota.fecha_hasta);
                            const fechaActual = new Date();
                            const desdeFecha = desde.getTime();
                            const hastaFecha = hasta.getTime();
                            let fechaActualFecha;

                            if(desdeFecha < hastaFecha ){

                                fechaActualFecha = fechaActual.getTime() + (1000 * 60 * 60 * 24 * 30);
                                if(filtroDia.includes('ESTE MES') && !(fechaActualFecha <= hastaFecha)) 
                                {
                                    return null
                                };
              
                                //en fechaActualFecha obtener el dia de hoy y sumarle una semana
                                fechaActualFecha = fechaActualFecha + (1000 * 60 * 60 * 24 * 7);
                                if((filtroDia.includes('ESTA SEMANA') || filtroDia.includes('ESTE FINDE')) && !(fechaActualFecha <= hastaFecha)){
                                    return null
                                }
              
                                fechaActualFecha = fechaActual.getTime();
                                if(filtroDia.includes('HOY') &&  !(fechaActualFecha <= hastaFecha)) 
                                {
                                    return null
                                };
                            
                            }
                            
                            if(!busqueda && filtro.length === 0) return <MarkerCustom select={selected} setSelect={setSelected} nota={nota} />

                            let arrayValues = Object.values(nota);
                            arrayValues.splice(6,1);

                            if(busqueda)
                            {
                                if(filtro.length > 0)
                                {
                                  if(arrayValues.some(item => filtro.includes(item)) && nota.titulo.toLowerCase().includes(busqueda.toLowerCase()))
                                    {
                                    return <MarkerCustom select={selected} setSelect={setSelected} nota={nota} />
                                    }
                                }else
                                {
                                    if(nota.titulo.toLowerCase().includes(busqueda.toLowerCase()))
                                    {
                                        return <MarkerCustom select={selected} setSelect={setSelected} nota={nota} />
                                    }   
                                }
                            } 
                            
                            if(filtro.length > 0 && !busqueda)
                            {
                                if(arrayValues.some(item => filtro.includes(item)))
                                {
                                    return <MarkerCustom select={selected} setSelect={setSelected} nota={nota} />
                                }
                            }
                        }
                    })
                )
                }
                )}
                

            </GoogleMap>
        )
    }
            
        
}
