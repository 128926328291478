import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './cultura.css';

export default function AccordionMap({select,setSelect,setCenter,item,busqueda,filtro,filtroDia}) {
  const [open, setOpen] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {

    let parent = document.getElementById(item.provincia);
    if(parent)
    { 
      let children = parent.getElementsByTagName("p");
      let cantidad = children.length;
      setCount(cantidad);
    }
    
  }, [item,filtro,filtroDia,busqueda]);



  return (
    <Row>
      <Col md={10} className='cursor-pointer' >
        <p 
        style={{color:'#000'}}
        className='container-locations-prov' 
        onClick={()=>setOpen(!open)}>{item.provincia}</p>
      </Col>
      <Col md={2} className="text-end" >
        {
          //obtener la cantidad de hijos de un elemento padre 
        }    
        <p>({ count })</p>
      </Col>
      <Col md={12} className="pt-2">
        {open ? (
          <div id={item.provincia} className="ps-2">
            {item.notas.map((nota, index) => {
              
              const desde = new Date(nota.fecha_desde);
              const hasta = new Date(nota.fecha_hasta);
              const fechaActual = new Date();
              const desdeFecha = desde.getTime();
              const hastaFecha = hasta.getTime();
              let fechaActualFecha;


              if(desdeFecha < hastaFecha ){

                  fechaActualFecha = fechaActual.getTime() + (1000 * 60 * 60 * 24 * 30);
                  if(filtroDia.includes('ESTE MES') && !(fechaActualFecha <= hastaFecha)) 
                  {
                      return null
                  };

                  //en fechaActualFecha obtener el dia de hoy y sumarle una semana
                  fechaActualFecha = fechaActualFecha + (1000 * 60 * 60 * 24 * 7);
                  if((filtroDia.includes('ESTA SEMANA') || filtroDia.includes('ESTE FINDE')) && !(fechaActualFecha <= hastaFecha)){
                      return null
                  }

                  fechaActualFecha = fechaActual.getTime();
                  if(filtroDia.includes('HOY') &&  !(fechaActualFecha <= hastaFecha)) 
                  {
                      return null
                  };
              
              }

              if(!busqueda && filtro.length === 0){
                return (
                  <p 
                  style={{
                    color:select === nota.idnotacultural ? 'blue' : '#000',
                    fontWeight:select === nota.idnotacultural ? 'bold' : 'normal',
                  }}
                  onClick={()=>{
                    setSelect(nota.idnotacultural);
                    setCenter({
                    lat: parseFloat(nota.latitud),
                    lng: parseFloat(nota.longitud),
                  })}}
                  className='py-1 cursor-pointer' 
                  key={index}
                  >
                  - {nota.titulo}
                  </p>
              )}

              let arrayValues = Object.values(nota);
              arrayValues.splice(6,1);

              if(busqueda){
                if(filtro.length > 0){
                  if(arrayValues.some(item => filtro.includes(item)) && nota.titulo.toLowerCase().includes(busqueda.toLowerCase())){
                    return (
                      <p 
                      style={{
                        color:select === nota.idnotacultural ? 'blue' : '#000',
                        fontWeight:select === nota.idnotacultural ? 'bold' : 'normal',
                      }}
                      onClick={()=>{
                        setSelect(nota.idnotacultural);
                        setCenter({
                        lat: parseFloat(nota.latitud),
                        lng: parseFloat(nota.longitud),
                      })}}
                      className='py-1 cursor-pointer' 
                      key={index}
                      >
                      - {nota.titulo}
                      </p>
                    )
                  }
                }else{
                  if(nota.titulo.toLowerCase().includes(busqueda.toLowerCase())){
                    return (
                      <p 
                      style={{
                        color:select === nota.idnotacultural ? 'blue' : '#000',
                        fontWeight:select === nota.idnotacultural ? 'bold' : 'normal',
                      }}
                      onClick={()=>{
                        setSelect(nota.idnotacultural);
                        setCenter({
                        lat: parseFloat(nota.latitud),
                        lng: parseFloat(nota.longitud),
                      })}}
                      className='py-1 cursor-pointer' 
                      key={index}
                      >
                      - {nota.titulo}
                      </p>
                    )
                  }
                }
              }

              if(filtro.length > 0 && !busqueda){
                if(arrayValues.some(item => filtro.includes(item))){
                  return (
                    <p 
                    style={{
                      color:select === nota.idnotacultural ? 'blue' : '#000',
                      fontWeight:select === nota.idnotacultural ? 'bold' : 'normal',
                    }}
                    onClick={()=>{
                      setSelect(nota.idnotacultural);
                      setCenter({
                      lat: parseFloat(nota.latitud),
                      lng: parseFloat(nota.longitud),
                    })}}
                    className='py-1 cursor-pointer' 
                    key={index}
                    >
                    - {nota.titulo}
                    </p>
                  )
                }
              }

            })}
          </div>
        ) : null}
      </Col>
    </Row>
  )
}
