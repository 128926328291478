import './viajes.css'
import { Row, Col, Button } from 'react-bootstrap';
import Bariloche from '../../assets/images/bariloche.png';
import Divider from '../Divider';

export default function Destacada() {
  return (
    <div>
        <Row className='container-destacada'>
            <Col md={6} className='p-0'>
                <div style={{height:'350px'}}>
                    <img className='destacada-img' src={Bariloche} alt='...' />
                </div>
            </Col>
            <Col md={6}>
                <div className='container'>
                    <Row className='pt-3'>
                        <h3 className='destacada-title'>Bariloche Semana Santa 2022</h3>
                    </Row>
                    <Row className='pt-2'>
                        <Col>
                        <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <p className='destacada-descripcion'><span style={{fontWeight:'700'}}> Duracion: </span>6 días 5 noches</p>
                        </Col>
                        <Col md={12} className='pt-2'>
                            <p className='destacada-descripcion'> Paquete Turístico visitando Bariloche, con Circuito chico y Cerro Campanario.</p>
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <p className='destacada-descripcion'> desde  
                            <span style={{fontWeight:'700'}}> ARS 55.096 </span>
                            <span className='destacada-oldPrice'> &nbsp;  $888888 </span> 
                            <span className='destacada-descuento ms-3'> 12% OFF</span>
                            </p>
                        </Col>
                        <Col md={12} className='pt-2'>
                            <p className='destacada-descripcionRed'> ¡Solicitá tu descuento de Estudiante!</p>
                            <p className='destacada-link'>¿Cómo comprar?</p>
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={7}>
                            <p className='destacada-minDescripcion'>Comercializado por: shareargentina.com.ar</p>
                        </Col>
                        <Col className='text-end' md={5}>
                            <Button bsPrefix='btn-destacada'>
                               Ver mas
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </div>
  )
}
