import { Container, Row, Col } from "react-bootstrap"
import CashBack from "../../assets/images/cashback.svg"
import './cashback.css'
export default function Cashback() {
  return (
    <Container fluid className="ContainCashback my-3 ">
        <Row className="ContainCashback align-middle " >
            <Col className='ColCashback '>
                <img src={CashBack} alt='CashBack' />
            </Col>
            <Col className='ContainCashText text-center p-5 p-md-5'>
                <p className="PCashback pb-5">
                Te reintegramos el <span className="PImportantCashBack">2% de todas tus compras</span> <br/> que realices en nuestro Marketplace <br/> con cualquier medio de pago, para que lo reutilices<br/> en productos de nuestra web.
                </p>
            </Col>
        </Row>

    </Container>
  )
}
