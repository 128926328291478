import './carrusel.css';
import CardCarrusel from '../CardCarrusel';
import Mostaza from '../../assets/images/mostaza_ok.png';
import Dia from '../../assets/images/dia_ok.png';
import Sube from '../../assets/images/sube_ok.png';
import Onfit from '../../assets/images/onfit.jpg';
import { useState, useEffect } from 'react';
import * as Services from '../../services';
import {Link} from 'react-router-dom';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

export default function Carrusel() {
  const [carrusel, setCarrusel] = useState([]);
  useEffect(() => {
    const getCarrusel = async () => {
      
      const data = await Services.getData('/obtenerBannersHome');
      setCarrusel(data);
    }
    getCarrusel();
  }
  , []);
  return (
    <div className="Carrusel container pb-5">
      <h2 className="h2Title">¡Beneficios para estudiantes como vos!</h2>
      
      <div className="CarruselContain ScrollCustom">
       {carrusel.length  &&   <>
        {carrusel.map((data, index) => {
          if(index < 4)
         { return (
            <CardCarrusel key={index} data={data} />
          )}
        }
        )}
          </>
          }
     
      </div>

      <div className='CarruselLink'>
        <Link to='/descuentos'>
          <h2 className='Link'>Ver Todos</h2>
        </Link>
      </div>
    </div>
  )
}
