import Divider from '../Divider';
import './footerderechos.css';
export default function FooterDerechos() {
  return (

    <div className='ContainDerechos'>
        <Divider isHorizontal={true} style={{backgroundColor:"var(--white)", width:"100%"}} />
        <p className='TitleDerechos'><bold> © DEPCSUITE S.A. 2022 </bold>- CUIT Nº 30-71683193-7. Todos los derechos reservados. Política de privacidad - www.depcsuite.com</p>
    </div>
  )
}
