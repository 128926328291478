import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Divider from '../Divider';

export default function Card({content}) {

    return (
        <div  className='me-3 card-cultura'>
            <Link to={`/cultural/circuitos/${content.idcircuito}`}>
                <Row>
                    <Col style={{height:'180px'}} xs={12}>
                        <img style={{borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} className='img-cultura-card' src={'https://app.depcsuite.com/storage/sistemacirca/circuito/'+ content.imagen} alt='...' />
                    </Col>
                </Row>
                <div className='contain-card-cultural'>
                    <Row className='py-4 text-center'>
                        <Col md={12}>
                            <p style={{fontWeight:'500',height:'36px'}}  className='cultura-card-text'> {content.titulo} </p>
                        </Col>
                    </Row>
                </div>
            </Link>
        </div>
    );

}