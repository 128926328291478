import FooterCred from '../FooterCred';
import FooterInfo from '../FooterInfo/FooterInfo';
import FooterDerechos from '../FooterDerechos/FooterDerechos'
import './footer.css'
export default function Footer({color}) {
  return (
    <div className="Footer" style={color ? {backgroundColor:color} :{backgroundColor:'transparent'}}>
        <FooterCred />
        <div className='FooterBGColor'>
        <FooterInfo />
        <FooterDerechos />
        </div>
    </div>
  )
}
