import { Button} from 'react-bootstrap';
import Divider from '../Divider';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './navbar.css'; 

export default function Navbar() {
  let location = useLocation();
  const [login, setLogin] = useState();

  const LogOut = () => {
    localStorage.removeItem('idbeneficiario');
    localStorage.removeItem('nombre');
    window.location.href = '/';
  }

  useEffect(() => {
    if (localStorage.getItem('idbeneficiario')) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }
  , [location])
  return (

    <div className='NavContainer'>
   { !login &&  <><Link to="/suscripcion">
    <Button bsPrefix='btnSub' variant="primary">SUSCRIBITE</Button>
    </Link>
    <Divider  style={{backgroundColor:"var(--grey)", height:"20px"}} />
    </>
    }
    
   { !login ? <Link to="/login">
    <Button textContent="INICIAR SESIÓN" bsPrefix='btnLogIn' variant="primary">INICIAR SESIÓN</Button>
    </Link>
    : <Link to="/micuenta">
    <Button  textContent="MI CUENTA" bsPrefix='btnMyacc' variant="primary">MI CUENTA</Button>
    </Link>
    }
    <Divider  style={{backgroundColor:"var(--grey)", height:"20px"}} />
    <p className='NavbarTitle'>PREGUNTAS FRECUENTES</p>
    <Divider  style={{backgroundColor:"var(--grey)", height:"20px"}} />
    <p className='NavbarTitle'>CONTACTO</p>
    <Divider  style={{backgroundColor:"var(--grey)", height:"20px"}} />
    </div>
  )
}
