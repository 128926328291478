import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const stepThreeValidationSchema = Yup.object({
    txtDireccion: Yup.string().required('La calle es requerida'),
    txtDireccionNumero: Yup.number().typeError('Ingresa solo numeros').required('El número es requerido'),
    lstLocalidad: Yup.string().required('La localidad es requerida'),
    lstProvincia: Yup.string().required('La provincia es requerida'),
   
    })

const StepThree = ( props) => {
    const handleSubmit = (values) => {
      props.next(values);
    }
    return (
    <Formik
        validationSchema={stepThreeValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
    >
     { ({values,errors,touched})=>(
      <Form>

        <Row>
          <Col className='text-center text-lg-start'>
          <h3 className='H3Pink30px'>Paso 3</h3>
          </Col>
        </Row>

        <Row className='pb-4 pt-3 pt-lg-0'>
            <Col className='text-center text-lg-start' xs={12} lg={8}>
                <p className='PFieldTitle pb-4 pb-lg-2'>¿Dónde enviamos tu credencial física?</p>
                { 
                errors.txtDireccion && touched.txtDireccion && 
                <div> <p className='PError'> {errors.txtDireccion} </p> </div> 
                }
                <Field 
                name="txtDireccion" 
                type="text" 
                placeholder="Calle" 
                className={"form-control " +  (errors.txtDireccion && touched.txtDireccion ? 'is-invalid' : '')} 
                />

                <p className='PFieldHelpText pt-2'> Obligatorio*</p>
            </Col>
        </Row>

        <Row className='pb-4' >
                {
                errors.txtDireccionNumero && touched.txtDireccionNumero && 
                <div> <p className='PError'> {errors.txtDireccionNumero} </p> </div> 
                }
            <Col xs={6} lg={1}>
                

                <Field 
                    name="txtDireccionNumero"
                    placeholder="Numero" 
                    type="text" 
                    className={"form-control pb-2 pt-2 " + (errors.txtDireccionNumero && touched.txtDireccionNumero ? 'is-invalid' : '')}
                />

                <p className='PFieldHelpText pt-2 d-none d-lg-block'> Obligatorio*</p>
            </Col>

            <Col xs={6} lg={1}>
                <Field 
                name="txtDireccionPiso" 
                placeholder="Piso" 
                type="text" 
                className="form-control pb-2 pt-2" />
            </Col>

            <Col className='pt-4 pt-lg-0' xs={6} lg={1}>
                <Field 
                name="txtDireccionDpto" 
                placeholder="Dpto." 
                type="text" 
                className="form-control pb-2 pt-2" 
                />
            </Col>
            <Col className='pt-4 pt-lg-0' xs={6} lg={1}>
                <Field 
                name="txtDireccionCp" 
                type="text" 
                placeholder="C.P" 
                className="form-control pb-2 pt-2" />
            </Col>
        </Row>

        <Row className='pb-4' >
                {
                errors.lstLocalidad && touched.lstLocalidad && 
                <div> <p className='PError'> {errors.lstLocalidad} </p> </div> 
                }
                 {
                errors.lstProvincia && touched.lstProvincia && 
                <div> <p className='PError'> {errors.lstProvincia} </p> </div> 
                }
            <Col xs={12} lg={4}>
                <Field 
                name="lstLocalidad"  
                placeholder="Localidad" 
                as="select" 
                className={"form-select pb-2 " + (errors.lstLocalidad && touched.lstLocalidad ? 'is-invalid' : '')}
                >
                    <option disabled selected className='DisableOption' value=''  >Localidad</option>
                    <option value="Buenos Aires" >Temperley</option>
                    <option value="Catamarca" >Lomas de Zamora</option>
                    <option value="Chaco" >Chaco</option>
                </Field>
                <p className='PFieldHelpText pt-2 '>Obligatorio*</p>
            </Col>

            <Col className='pt-4 pt-lg-0' xs={12} lg={4}>
                <Field 
                name="lstProvincia" 
                as="select" 
                className={"form-select pb-2 " + (errors.lstProvincia && touched.lstProvincia ? 'is-invalid' : '')}
                >
                    <option disabled selected className='DisableOption' value='' >Provincia</option>
                    <option value="Buenos Aires">Buenos Aires</option>
                    <option value="Catamarca">Catamarca</option>
                    <option value="Chaco">Chaco</option>
                </Field>
                <p className='PFieldHelpText pt-2 '>Obligatorio*</p>
            </Col>


        </Row>

        <Row className='pt-4 pt-lg-0'>
            <Col xs={12} lg={8}>
                <Field name="txtDetalles" type="text" as="textarea" placeholder="Detalles del lugar que nos ayuden a entregarte la tarjeta" className="form-control" />
                <p className='PFieldHelpText pt-2 d-none d-lg-block'>Detalles del lugar que nos ayuden a entregarte la tarjeta</p>
            </Col>
        </Row>

        <Row className='pt-5'>
            <Col  className='pt-3'>
                <Button bsPrefix='btnPrev' type='button' onClick={()=> props.prev(values)} >&lt; Volver</Button>
            </Col>

            <Col className='text-center'>
                <Button bsPrefix='btnNext' type='submit'>Siguiente</Button>
            </Col>
      </Row>
      </Form>
     )}
    </Formik>
    )}

    export default StepThree;