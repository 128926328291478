
import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useState, useEffect } from 'react';
import * as Services from '../../services';



  


const stepOneValidationSchema = Yup.object({
  txtNombreCentro: Yup.string().when('lstCentro', {
    is: (value) => value === 'otro',
    then: Yup.string().required('Es obligatorio el ingreso del nombre del centro'),
  })
    
  })
  
const StepOne = (props) => {

  const [centros, setCentros] = useState([]);
useEffect(() => {
  const fetchData = async () => {
    const data = await Services.getAllEmpresas();
    setCentros(data);
  }
  fetchData();
}
, []);

    const handleSubmit = (values) => {
      props.next(values);
    }
    return (
      
    <Formik
      
      validationSchema={stepOneValidationSchema}
      initialValues={props.data}
      onSubmit={handleSubmit}
    >
     { ({
     errors,
     touched,
     values
    })=>(
     
      <Form>
        <Row>
          <Col className='text-center text-lg-start'>
          <h3 className='H3Pink30px'>Paso 1</h3>
          </Col>
        </Row>

        <Row className='justify-content-center justify-content-lg-start pt-2 pb-5'>
          <Col className='text-center text-lg-start' xs={11} lg={5}>

              <p className='PFieldTitle pb-2'>¿Dónde estudiás?</p>
              <Field  name="lstCentro" as="select" className="form-select  pb-2 " >
                <option value="" disabled>Seleccione un centro</option>
                {
                  centros.map((centro, index) => {
                    return <option key={index} value={centro.idempresa}>{centro.empresa}</option>
                  }
                  )
                }
                <option value='otro'>Otro</option>
              </Field>

              
          </Col>
        </Row>
          
        {values.lstCentro ==='otro' ?
        <Row className='justify-content-center justify-content-lg-start'>

          <Col className='text-center text-lg-start' xs={11} lg={8}>
              <p className='PFieldTitle pb-2'>¿En qué centro educativo estudiás?</p>
              { errors.txtNombreCentro && touched.txtNombreCentro && 
                <div> <p className='PError'> {errors.txtNombreCentro} </p> </div> 
                }
              <Field 
              name="txtNombreCentro" 
              type="text" 
              placeholder="Nombre del centro" 
              className={"form-control pb-2 "+ (errors.txtNombreCentro && touched.txtNombreCentro ? 'is-invalid' : '')}
              
              />

              <p className='PFieldHelpText  pt-2'> Obligatorio*</p>
             
          </Col>
        </Row>
        : <></>}
        <Row className='pt-5'>
          <Col className='text-center'>
            <Button bsPrefix='btnNext' type='submit'>Siguiente</Button>
          </Col>
        </Row>
      </Form>
     )}
    </Formik>
    )}

export default StepOne;