import './viajes.css'
import { Row, Col, Button } from 'react-bootstrap';
import Divider from '../Divider';
import Viajes from '../../assets/images/viajes_grecia.png';

export default function MiniCards() {
  return (
    <div>
        <Row style={{height:'231px'}} className='pt-3 pb-3'>
            <Col  md={12}>
                <img style={{borderTopRightRadius:'10px',borderBottomLeftRadius:'0px'}} src={Viajes} className='destacada-img' alt='...' />
            </Col>
        </Row>
        <Row className='container container-destacada pt-4 pb-3' >
            <Col className='text-center' md={12}>
                <h3 style={{fontSize:'20px'}} className='destacada-title'>Grecia y Turquía</h3>
            </Col>
            <Col md={12}>
                <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
            </Col>
            <Col className='pt-3 text-center' md={12}>
                <p  className='destacada-descripcion'><span style={{fontWeight:'700'}}> Duracion: </span> 6 dias / 5 noches</p>
            </Col>
            <Col className='pt-3' md={12}>
                <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
            </Col>
            <Col className='pt-3 text-center' md={12}>
                <p className='destacada-descripcion'> desde  
                    <span style={{fontWeight:'700'}}> ARS 55.096 </span>
                    <span className='destacada-oldPrice'>$888888 </span> <br/>
                    <span className='destacada-descuento ms-3'> 12% OFF</span>
                </p>
            </Col>
            <Col className='pt-4 text-center' md={12}>
                <Button bsPrefix='btn-destacada'>
                        Ver mas
                </Button>
            </Col>
            <Col className='pt-4 text-center' md={12}>
                <p className='destacada-link'>¿Cómo comprar?</p>
                <p className='destacada-minDescripcion pt-1'>Comercializado por: shareargentina.com.ar</p>
            </Col>

        </Row>


    </div>
  )
}
