import './marketplacecarrusel.css';
import CardMarket from '../CardMarket';
import Sube from '../../assets/images/tarjeta_sube.svg'
import HP from '../../assets/images/HP_1.png'
import SSD from '../../assets/images/SSD.jpg'
import Headphones from '../../assets/images/headphones.png'
export default function MarketPlaceCarrusel() {
  return (
    <div className="ContainMarket container">
        <h2 className="h2Title">Descubrí el Marketplace con Descuentos y CASHBACK por cada compra:</h2>
    <div className='ContainCards ScrollCustomGrey'>
        <CardMarket title="Nombre Producto  Nombre Producto" price="888888" oldPrice="888888" discount="12" image={Sube} />
        <CardMarket title="Nombre Producto  Nombre Producto" price="888888" oldPrice="888888" discount="12" image={HP} />
        <CardMarket title="Nombre Producto  Nombre Producto" price="888888" oldPrice="888888" discount="12" image={Headphones} />
        <CardMarket title="Nombre Producto  Nombre Producto" price="888888" oldPrice="888888" discount="12" image={SSD} />
    </div>
    <div className='CarruselLink'>
          <h2 className='Link pt-3 pb-3'>Ver Todos</h2>
    </div>
    </div>
  )
}
