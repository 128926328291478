import DescuentoCard from './DescuentoCard';
import { Carousel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import * as Service from '../../services';
import './descuentohero.css'


export default function DescuentoHero() {
    const [banners, setBanners] = useState([]);
    useEffect(() => {
        const getBanners = async () => {
            const data = await Service.getBanners();
            setBanners(data);
        }
        getBanners()
    }, []);
  return (
    <Carousel fade >
        {banners.map((banner,index) => {
            return (
                <Carousel.Item key={index}>

                    <DescuentoCard
                    descuento={banner}
                    img={`https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/${banner.bannerweb}`}
                    />

                </Carousel.Item>
        )})}
        </Carousel>
  )
}
