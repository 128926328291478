import {Row, Col, Button} from 'react-bootstrap';
import './profile.css';
import Welcome from '../../assets/images/hola_usuario.png';
import Divider from '../Divider';
import { useState, useEffect } from 'react';
import * as Services from '../../services';
import SubMenu from './SubMenu';
import DetallesPerfil from './DetallesPerfil';
import DireccionesPerfil from './DireccionesPerfil';
import MisCompras from './MisCompras';

export default function Profile() {

    const [user, setUser] = useState({});
    const [actPantalla, setActPantalla] = useState(1);
    useEffect(() => {
        const getUser = async () => {
            const user = await Services.getBeneficiarioId(localStorage.getItem('idbeneficiario'));
            setUser(user);
        }
        getUser();
    }
    , []);

  return (
    <div className='pt-4'>
        <Row className='d-none d-lg-flex'>
            <Col className='ms-3 '  xs={2}>
               <SubMenu pantalla={actPantalla} setPantalla={setActPantalla} />
            </Col>
            <Col container xs={9} className='contain-myaccount ms-4 px-5'>
                {
                    actPantalla === 1 ?
                    <DetallesPerfil user={user} />
                    :
                    actPantalla === 2 ?
                    <DireccionesPerfil user={user} />
                    :
                    actPantalla === 3 ?
                    <MisCompras user={user} />
                    :
                    null

                }
            </Col>
        </Row>


        <Row className='d-lg-none min-vh-100'>
            <Col xs={12} >
                <Row className='ps-3'>
                    <Col xs={4}>
                    <img src={Welcome} alt=""/>
                    </Col>
                    <Col xs={8} className='text-center d-flex justify-content-center align-items-end '>
                        <div>
                            <Button bsPrefix='btn-ver-credencial'>Ver credencial</Button>
                        </div>
                    </Col>
                </Row>
                <Row className='container pb-2 pt-5'>
                    <Col xs={12}>
                    <p className='mobile-title'>Detalles del perfil</p>
                    </Col>
                </Row>
                <Row >
                    <Col xs={12}>
                    <Divider isHorizontal style={{backgroundColor:"var(--white)"}} />
                    </Col>
                </Row>
                <Row className='container'>
                    <Col xs={12} className='pt-3'>
                        <p className='mobile-category-name'>{user.nombre+ " " +user.apellido}</p>
                    </Col>
                    <Col xs={12} className='pt-3'>
                        <p className='mobile-category'> {user.email}</p>
                    </Col>
                    <Col xs={12} className='pt-3'>
                        <p className='mobile-category'>{user.telefono}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='text-end pt-5'>
                        <p className='mobile-link'>Editar</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='pt-2'>
                        <Divider isHorizontal style={{backgroundColor:"var(--white)"}} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='text-end pt-4'>
                        <p className='mobile-link'>Cambiar Contraseña</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
  )
}
