import { Container, Row } from 'react-bootstrap'
import CardDescuento from './CardDescuento'
import './descuentobody.css'

export default function CarouselDescuentos({title, descuentos,style}) {
const styles = style === 'white' ? 'ScrollCustom' : 'ScrollCustomGrey';
   return (
    <Container fluid  className='py-3'>
        <Row className='pb-3'>
            <p className='PTitleCarousel'>{title}</p>
        </Row>
        <Row >
            <div className={'ContainerCarouselDescuentos pb-4 ' + styles}>
            {descuentos.map((descuento,index) => {
                return (
                    <CardDescuento
                        key={index}
                        nombre={descuento.nombre_comercio}
                        id={descuento.id}
                        src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/'+descuento.imagen_ilustrativa}
                    />
                )
            })}
            </div>
        </Row>
       
    </Container>
  )
}
