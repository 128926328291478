import './footerqr.css'

export default function FooterQR({qr}) {
  return (
    <div className="ContainQR">
        <div className='ContainQRImg'>
            <img src={qr} alt="qr" />
        </div>
        <div className='ContainQRText'>
            <p className="TitleQR">Tus descuentos en un solo paso</p>
            <p className='SubtitleQR'>Escaneá el QR con la cámara de tu celular y descargá la App.</p>
        </div>
    </div>
  )
}
