import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import * as Service from "../../services";
import MarkerCustom from "./MarkerCustom";

export default function Map({filters,busqueda}) {
  const [locales, setLocales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [center, setCenter] = useState({
    lat: -34.6037,
    lng: -58.3816,
    });
 



  useEffect(() => {
    const getLocales = async () => {
      const data = await Service.getAllLocales();
      setLocales(data);
      setIsLoading(false);
    }
    navigator.geolocation.getCurrentPosition(function(position) {
        setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        });
    }
    );
    getLocales();
  }, []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ,
    })
    if (!isLoaded) {
        return <div>Loading...</div>;
    }else{

    return (
        <GoogleMap
            id="map"
            mapContainerStyle={{
                height: "70vh",
                width: "100%",
            }}
            zoom={13}
            center={center}
        >
            {isLoading?
            <Marker 
            position={{ lat: -34.6037, lng: -58.3816 }}
            />:
            locales.sucursales.map((local, index) => {
                if(filters.length > 0){
                    if(filters.includes(local.nombre_rubro)){
                       
                if(local.nombre_comercio.toLowerCase().includes(busqueda.toLowerCase())){
                return (
                    <MarkerCustom key={index} local={local} />
                )
                }
                }
            }else{
                if(local.nombre_comercio.toLowerCase().includes(busqueda.toLowerCase())){
                return (

                    <MarkerCustom key={index} local={local} />
                )
                }
            }
                })
            }
        </GoogleMap>
    );
}
}
