import './notacultural.css'
import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Carrusel from '../Cultura/Carrusel';
import Star from '../../assets/images/icon-star.svg';
import { Row, Col, Container } from 'react-bootstrap';
import * as Services from '../../services';
import Divider from '../Divider';

export default function NotaCultural() {
    const { id } = useParams();
    const [notaCultural, setNotaCultural] = useState();
    const [cultura , setCultura] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            const notaCultural = await Services.getNotaId(id);
            const data = await Services.getData('/getAllNotas');
            console.log(id);
            setCultura(data);
            setNotaCultural(notaCultural);
        }
        fetchData();
    }, [id]);
  return (
    <div style={{overflow:'hidden'}}>
        <Row>
            <Col>
            <div style={{height:'371px',position:'relative'}}>
                <div style={{position:'relative',width:'100vw'}}>
                    <img style={{height:'371px',width:'100%',objectFit:'cover'}} src={notaCultural && "https://app.depcsuite.com/storage/sistemacirca/imagenes_cultura/"+ notaCultural.imagen_principal} alt=""/>
                </div>
                <div className='contain-text-nota-cultural'>
                <p>Presentá tu <span style={{color:'var(--pink)',fontWeight:'600'}}>Credencial Estudiantil</span>  para acceder a los Beneficios.</p>
                </div>
            </div>
            </Col> 
        </Row>
        {notaCultural ?
        <Container  className='pt-4 container-fluid container-md'>
            <div className='mx-md-5 px-md-5'>
                <Row>
                    <Col>
                        <h2 className='titulo-nota-cultural'>{notaCultural.titulo}</h2>
                    </Col>
                </Row>
                <Row className='pb-5'>
                    <Col>
                        <div className='contain-descripcion-nota-cultural' dangerouslySetInnerHTML={ {__html: notaCultural.descripcion}}></div>
                    </Col>
                </Row>
                <Row className='py-5'>
                    <Col>
                    <Divider isHorizontal style={{height:'1px',backgroundColor:'var(--grey)'}} />
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col>
                        <Carrusel content={cultura} icon={Star} titulo="¡Circuito recomendado para tu salida!" />
                    </Col>
                </Row>
            </div>

        </Container> :null}
    </div>
    
  )
}
