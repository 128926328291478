import { Button, Container, Row, Col } from 'react-bootstrap';
import { FaTimes} from "react-icons/fa";
import '../../assets/css/login.css';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import * as Service from '../../services/';

const validationSchema = Yup.object({
    txtEmail: Yup.string().email('El email no es válido').required('Es obligatorio el ingreso del email'),
    txtPassword: Yup.string().required('Es obligatorio el ingreso de la contraseña'),
})

export default function Login() {
  return (
    <div className='ContainerAllLogin' >
        <Row className='justify-content-center'>
            <Col md={6} className=''>
                <Container className='ContainerInputForm my-5 px-md-5 ' >
                    <Row className='justify-content-end'>
                        <Col xs={1}>
                        <FaTimes style={{color:"var(--red)"}}/>
                        </Col>
                    </Row>
                    <Row className='text-center'>
                        <h2 className='TitleFormLogin'>Iniciar Sesión</h2>
                    </Row>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{
                            txtEmail: '',
                            txtPassword: ''
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            setSubmitting(true);
                            const logIn = await Service.login(values.txtEmail, values.txtPassword);
                            if(logIn.code === 200){
                                localStorage.setItem('idbeneficiario', logIn.body.idBeneficiario);
                                localStorage.setItem('nombre', logIn.body.nombre);
                            
                                window.location.href = '/';
                            }
                            setSubmitting(false);
                        }}
                    >
                    {({ errors, touched }) => (
                        <Form>
                            <Row className='justify-content-center py-3'>
                                <Col md={10}>
                                    <p className='PFieldLogin pb-2 ps-2'>Correo electrónico</p>
                                    <Field name="txtEmail" type="email" placeholder="Ej: juanperez@gmail.com" className='form-control' />
                                    {errors.txtEmail && touched.txtEmail && (
                                        <div className='ErrorForm'>
                                            <p className='PError'>{errors.txtEmail}</p>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row className='justify-content-center py-3'>
                                <Col md={10}>
                                    <p className='PFieldLogin pb-2 ps-2'>Contraseña</p>
                                    <Field name="txtPassword" type="password" placeholder="Ingrese su contraseña" className='form-control' />
                                    {errors.txtPassword && touched.txtPassword && (
                                        <div className='ErrorForm'>
                                            <p className='PError'>{errors.txtPassword}</p>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row className='justify-content-center pt-5 text-center'>
                                <Col xs={12} md={6}>
                                    <Button type="submit" bsPrefix='BtnFormLogin'>Ingresar</Button>
                                </Col>
                                <Col xs={12} md={6} className='align-self-center'>
                                    <p className='PFieldLogin'>¿No tenés cuenta? <a className='LinkLogin'>Suscribite</a> </p>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    </Formik>
                   
                </Container>
        </Col >
        </Row>
    </div>
  )
}
