import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import {Outlet} from 'react-router-dom';

export default function Layout ({footer = true, navbar = true, header = true,footerColor}) {
    return (
        <div>
       {navbar && <Navbar />}
       {header && <Header />}
        <main >
         <Outlet/>
         </main>
       {footer && <Footer color={footerColor ? "var(--light-grey)" : null} />}
        </div>
    )
}