import React from 'react'
import {Row, Col, InputGroup, Form } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import AccordionDescuento from '../DescuentoBody/AccordionDescuento';
import { FaTimes, FaSistrix } from "react-icons/fa";
import * as Services from '../../services';


export default function LeftMenu({filtro,setFiltro}) {
    const [categorias, setCategorias] = useState([])
    const [marcas, setMarcas] = useState([]);

    const relleno = [{
        nombre: "Relleno",
    }, {
        nombre: "Relleno3",
    }, {
        nombre: "Relleno5",
    }
];

    useEffect(() => {
        const getData = async () => {
            const data = await Services.getData('/getCategorias');
            const result = await Services.getData('/getMarcas');
            setCategorias(data);
            setMarcas(result);
            console.log(data);
            }
        getData();
    } , []);

    const handleDeleteFiltro = (nombre) => {
        setFiltro(filtro => filtro.filter(item => item !== nombre));
    };

  return (
    <div className='BodyFilters container '>
        <Row>
            <Col md={12}>
                <div className='my-3'>
                    <InputGroup>
                        <Form.Control
                        style={{borderRight:'none',borderTop:'none',borderLeft:'none',borderBottom:'1px solid var(--grey)'}}
                        placeholder="Buscar"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"

                        />
                    
                    <InputGroup.Text 
                        className='d-none d-md-block'
                        style={{backgroundColor:'white',borderLeft:'none',borderTop:'none',borderRight:'none',borderBottom:'1px solid var(--grey)'}} 
                        id="basic-addon2">
                        <FaSistrix color='var(--grey)' fontSize='17px'/>
                    </InputGroup.Text>
                    </InputGroup>
                </div>
            </Col>
        </Row>
        <Row className=' justify-content-center py-2'>
            <p 
            style={{cursor:'pointer'}}
            className='PReinciarFiltros text-uppercase' 
            onClick={()=>setFiltro('') }
            >
                Reiniciar filtros
            </p>
        </Row>
        <Row>
            {filtro ? 
            filtro.map((filtro,index) => {
                return (
            <div className='ContainFilters m-1'>
            <p className='PFiltroGrey pe-2'>{filtro}</p>
            <FaTimes style={{cursor:'pointer'}} onClick={()=>handleDeleteFiltro(filtro) } color='var(--grey)' fontSize='11px'/>
            </div> 
                )
            })
            : null}
        </Row>
        <Row className='py-3'>
            <AccordionDescuento divider filtro={filtro}  setFiltro={setFiltro} title='PRODUCTO' content={relleno} />
        </Row>
        <Row className='py-2'>
            <AccordionDescuento divider filtro={filtro}  setFiltro={setFiltro} title='MARCA' content={marcas} />
        </Row>
        <Row className='py-2'>
            <AccordionDescuento divider filtro={filtro}  setFiltro={setFiltro} title='CATEGORIAS' content={categorias} />
        </Row>
    </div>
  )
}
