import './cardcarrusel.css';
//import Card from 'react-bootstrap/Card';

export default function CardCarrusel({title, description, image,data}) {
  return (
    <div className='CardCarrusel cursor-pointer'>
      <a href={data.link}>
        <div className="ContainImgCarrusel">
          <img className='ImgCarrusel '  src={`https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/${data.bannerweb}`} alt={data.promocion}/>
        </div>
        <div className="ContainTextCarrusel">
          <h2 className="TitleCard">{data.promocion}</h2>
          <p className="DescriptionCard">{data.descripcion}</p>
        </div>
      </a>
  </div>
  )
}
