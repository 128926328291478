import './viajes.css'
import {  Container, Row, Col, InputGroup, Form } from 'react-bootstrap';
import Divider from '../Divider';
import { FaTimes, FaSistrix } from "react-icons/fa";
import { useState } from 'react';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import AccordionDescuento from '../DescuentoBody/AccordionDescuento';

export default function LeftMenu() {
    const [filtro, setFiltro] = useState([]);
    const [open, setOpen] = useState(false);

    const relleno = [{
        nombre: "Relleno",
    }, {
        nombre: "Relleno3",
    }, {
        nombre: "Relleno5",
    }
];

    const handleDeleteFiltro = (nombre) => {
        setFiltro(filtro => filtro.filter(item => item !== nombre));
    };


  return (
    <Container >
        <div className='container-viajes  container'>
                    <Row>
                        <Col md={12}>
                            <div className='my-3'>
                                <InputGroup>
                                    <Form.Control
                                    style={{borderRight:'none',borderTop:'none',borderLeft:'none',borderBottom:'1px solid var(--grey)'}}
                                    placeholder="Buscar"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"

                                    />
                                
                                <InputGroup.Text 
                                    className='d-none d-md-block'
                                    style={{backgroundColor:'white',borderLeft:'none',borderTop:'none',borderRight:'none',borderBottom:'1px solid var(--grey)'}} 
                                    id="basic-addon2">
                                    <FaSistrix color='var(--grey)' fontSize='17px'/>
                                </InputGroup.Text>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row className=' justify-content-center py-2'>
                                <p 
                                style={{cursor:'pointer'}}
                                className='PReinciarFiltros text-uppercase' 
                                onClick={()=>setFiltro('') }
                                >
                                    Reiniciar filtros
                                </p>
                            </Row>
                            <Row>
                            {filtro ? 
                                filtro.map((filtro,index) => {
                                    return (
                                        <div className='ContainFilters m-1'>
                                            <p className='PFiltroGrey pe-2'>{filtro}</p>
                                            <FaTimes style={{cursor:'pointer'}} onClick={()=>handleDeleteFiltro(filtro) } color='var(--grey)' fontSize='11px'/>
                                        </div> 
                                    )
                                })
                            : null}
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <p className='leftMenu-subtitle'>Fechas</p>
                        </Col>
                        <Col md={12} className='pt-3'>
                            <Row>
                                <Col md={3}>
                                    <p className='leftMenu-value'>Desde</p>
                                </Col>
                                <Col md={6}>
                                    <input type='date' className='input-date'/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className='pt-3'>
                            <Row>
                                <Col md={3}>
                                    <p className='leftMenu-value'>Hasta</p>
                                </Col>
                                <Col md={6}>
                                    <input type='date' className='input-date'/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='py-4'>
                        <Col md={12}>
                            <Divider isHorizontal style={{backgroundColor:"var(--grey)" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={5}>
                                    <p className='leftMenu-subtitle'>Duracion</p>
                                </Col>
                                <Col md={6}>
                                    <InputGroup>
                                        <Form.Control
                                        style={{borderRight:'none',borderTop:'none',borderLeft:'none',borderBottom:'1px solid var(--grey)'}}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        />

                                    </InputGroup>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='py-4'>
                        <Col md={12}>
                            <Divider isHorizontal style={{backgroundColor:"var(--grey)" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex flex-row align-items-center justify-content-between pb-2">
                                <p className='leftMenu-subtitle'>Destinos</p>
                                { open ? <FaAngleUp className="cursor-pointer" fontSize='17px' onClick={() => setOpen(false)} /> : <FaAngleDown className="cursor-pointer" fontSize='17px' onClick={() => setOpen(true)} /> }
                            </div>
                        </Col>
                        <Col md={12}>
                           { open &&<>
                            <Row>
                                <AccordionDescuento subtitle title='Region' content={relleno}  filtro={filtro}  setFiltro={setFiltro} />

                            </Row>
                            <Row>
                                <AccordionDescuento subtitle title='Paises' content={relleno}  filtro={filtro} setfiltro={setFiltro} />
                            </Row>
                            <Row>
                                <AccordionDescuento subtitle title='Ciudades' content={relleno}  filtro={filtro} setfiltro={setFiltro} />
                            </Row>
                            </>
                            }
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <Divider isHorizontal style={{backgroundColor:"var(--grey)" }} />
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                        <AccordionDescuento  title='CATEGORIAS' content={relleno}  filtro={filtro} setfiltro={setFiltro} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Divider isHorizontal style={{backgroundColor:"var(--grey)" }} />
                        </Col>
                    </Row>
                    <Row className='pb-4'>
                        <Col md={12}>
                            <p className='leftMenu-subtitle'>Precio</p>
                        </Col>
                        <Col md={12}>
                            <p className='leftMenu-value'>25.000$</p>
                        </Col>
                    </Row>
                </div>
        </Container>
  )
}
