import './marketplace.css';
import { Button, Row, Col } from 'react-bootstrap';
import CashBack from '../../assets/images/icon-cashback.svg'
import Ahora12 from '../../assets/images/icon-ahora12.svg'
import Envio from '../../assets/images/icon-envio.svg'
import {Link } from 'react-router-dom';

export default function CardMarket({content,destacado }) {


  return (
    <div className='me-5 market-card bg-white'>
      <Link to={'/marketplace/producto/' + content.idproducto } >
        <Row>
            <Col style={{height:'200px'}} xs={12}>
              <img className='market-card-img p-2'  src={'https://sistema.depcsuite.com/productos/'+content.imagen} alt={content.nombre}/>
            </Col>
        </Row>
        <div className="ContainTextMarket">
            <p className="TitleMarket">{content.nombre}</p>
            { content.precio_oferta ? <>  <div className='ContainOldPrice' style={{textAlign:'center'}}>
           <p className="OldPriceMarket">$ {content.precio_pesos}</p>
              <p className="DiscountMarket">{content.porcentaje_descuento}% OFF</p>
            </div>
            <p className="PriceMarket">$ {content.precio_oferta}</p>
            </>
            : <p className="PriceMarket pt-4">$ {content.precio_pesos}</p>
            }
            </div>
        <div className=" py-4 ContainButtonMarket">
            <Button bsPrefix='btnBuy' variant="primary"><a style={{color:'white'}} href={'/marketplace/producto/' + content.idproducto }>Comprar</a></Button>
        </div>
        {!destacado && <Row className='pb-3 px-3 text-center justify-content-center'>
              <Col md={3}>
                <img src={CashBack} alt="CashBack" className="CashBack" />
              </Col>
              <Col md={3} className='pe-0'>
                <img src={Ahora12} alt="CashBack" className="CashBack" />
              </Col>
             {content.envio === "1" ? <Col md={3} className='ps-0'>
                <img src={Envio} alt="CashBack" className="CashBack" />
              </Col>
              :<></>  
              }
           </Row>
        }
      </Link>

    </div>
  )
}
