import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Divider from '../Divider';

export default function Card({isLittle, content}) {
    const recortarFecha = (fecha) => {
        const fechaMinimizada = fecha.slice(0,10).split('-').reverse().join('/');
        return fechaMinimizada;
    }


  return (
    
    <div  className='me-3 card-cultura'>
        <Link to={`/cultural/nota/${content.idnotacultural}`}>
        <Row>
            <Col style={{height:'180px'}} xs={12}>
                <img style={{borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} className='img-cultura-card' src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_cultura/'+ content.imagen_principal} alt='...' />
            </Col>
        </Row>
        <div className='contain-card-cultural'>
        {!isLittle ? <>  
            <Row className='pt-3 text-center'>
                <Col md={12}>
                    <p style={{fontWeight:'500'}}  className='cultura-card-text'>{content.rubro}</p>
                </Col>
            </Row>
            
            <Row className='pt-3 text-center'>
                <Col md={10} className='mx-auto'>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey'}}/>
                </Col>
            </Row>
            <Row className='pt-3'>
                <Col md={12} className='text-center'>
                    <p style={{fontWeight:'700',height:'36px'}} className='cultura-card-text '>{content.titulo}</p>
                </Col>
            </Row>
           
           <Row className='pt-3'>
                <Col md={10} className='mx-auto'>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey'}}/>
                </Col>
            </Row>
            <Row className='pt-3'>
                <Col md={12} className='text-center'>
                    <p className='cultura-card-text'>desde el <span style={{fontWeight:'700'}}>{recortarFecha(content.fecha_desde)}</span></p>
                </Col>
            </Row>
            <Row className='pt-3 pb-3'>
                <Col md={12} className='mx-auto text-center'>
                    <Button bsPrefix='btn-cultura-card'>
                        <Link style={{color:'var(--white)'}} to={'/cultural/nota/'+ content.idnotacultural }>Ver mas</Link>
                        </Button>
                </Col>
            </Row>
            </>
            :
            <Row className='py-4 text-center'>
            <Col md={12}>
                <p style={{fontWeight:'500',height:'36px'}}  className='cultura-card-text'> {content.titulo} </p>
            </Col>
        </Row>

            }
        </div>
        </Link>
    </div>
  )
}
