import '../Viajes/viajes.css'
import {  Container, Row, Col, InputGroup, Form } from 'react-bootstrap';
import Divider from '../Divider';
import { FaTimes, FaSistrix } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import * as Service from '../../services';
import AccordionDescuento from '../DescuentoBody/AccordionDescuento';


export default function LeftMenu({set,filtro,setFiltro,filtroDia,setFiltroDia}) {
    
    const [rubro, setRubro] = useState([]);
    const [prov, setProv] = useState([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const data = await Service.getData('/getAllRubros');
            const dataprov = await Service.getData('/getAllProvincias');
            setRubro(data);
            setProv(dataprov);
        }
        fetchData();
    }
    , []);

    const handleOnChange = (e) => {
        set(e.target.value);

    }

    const relleno = [{
        nombre: "HOY",
    }, {
        nombre: "ESTE FINDE",
    }, {
        nombre: "ESTA SEMANA",
    },{
        nombre: "ESTE MES",
    }

];

    const handleDeleteFiltro = (nombre) => {
        setFiltro(filtro => filtro.filter(item => item !== nombre));
    };
    const handleDeleteFiltroDia = (nombre) => {
        setFiltroDia(filtroDia => filtroDia.filter(item => item !== nombre));
    };

  return (
    <Container >
        <div className='container-viajes  container'>
                    <Row>
                        <Col md={12}>
                            <Row className=' justify-content-center py-2'>
                                <p 
                                style={{cursor:'pointer'}}
                                className='PReinciarFiltros text-uppercase' 
                                onClick={()=>{
                                    setFiltro('');
                                    setFiltroDia('');
                                } }
                                >
                                    Reiniciar filtros
                                </p>
                            </Row>
                            <Row>
                            {filtro ? 
                                filtro.map((filtro,index) => {
                                    return (
                                        <div className='ContainFilters m-1'>
                                            <p className='PFiltroGrey pe-2'>{filtro}</p>
                                            <FaTimes style={{cursor:'pointer'}} onClick={()=>handleDeleteFiltro(filtro) } color='var(--grey)' fontSize='11px'/>
                                        </div> 
                                    )
                                })
                            : null}
                            {filtroDia ?
                                filtroDia.map((filtro,index) => {
                                    return (
                                        <div className='ContainFilters m-1'>
                                            <p className='PFiltroGrey pe-2'>{filtro}</p>
                                            <FaTimes style={{cursor:'pointer'}} onClick={()=>handleDeleteFiltroDia(filtro) } color='var(--grey)' fontSize='11px'/>
                                        </div> 
                                    )
                                })
                            : null}
                            
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <AccordionDescuento divider title='¿CUÁNDO?' content={relleno}  filtro={filtroDia} setFiltro={setFiltroDia} />
                        </Col>
                    </Row>

                    <Row className='pt-3'>
                        <Col md={12}>
                            <AccordionDescuento divider title='RUBRO' content={rubro}  filtro={filtro} setFiltro={setFiltro} />
                        </Col>
                    </Row>
                    <Row className='py-3'>
                        <Col md={12}>
                            <AccordionDescuento divider title='UBICACIÓN' content={prov}  filtro={filtro} setFiltro={setFiltro} />
                        </Col>
                    </Row>

                </div>
        </Container>
  )
}
