import { Link } from 'react-router-dom'
import './descuentobody.css'



export default function CardDescuento({nombre,src,id}) {
  return (
    <div className='CardDescuento me-3 '>
      <Link to={`/descuentos/${id}`}>
    <div className='ContainerImgDescuento'>
    <img className='ImgCardDescuento' src={src} alt="Descubrilo" />
    </div>
   
    <div className='BodyCardDescuento d-flex justify-content-center align-items-center'>
        <p className='PCategoria text-uppercase'>
            {nombre}
        </p>
    </div>
    </Link>
</div> 
  )
}
