import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const stepTwoValidationSchema = Yup.object({
    txtNombre: Yup.string().min(3, 'El nombre debe tener al menos 3 caracteres').required('El nombre es requerido'),
    txtApellido: Yup.string().min(3, 'El apellido debe tener al menos 3 caracteres').required('El apellido es requerido'),
    txtEmail: Yup.string().email('El e-mail no es válido').required('El e-mail es requerido'),
    txtEmailConfirm: Yup.string().oneOf([Yup.ref('txtEmail'), null], 'El e-mail no coincide').required('El e-mail es requerido'),
    })

const StepTwo = ( props) => {
    const handleSubmit = (values) => {
      props.next(values);
    }
    return (
    <Formik
        validationSchema={stepTwoValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
    >
     { ({values,errors,touched})=>(
      <Form>

        <Row>
          <Col className='text-center text-lg-start'>
          <h3 className='H3Pink30px'>Paso 2</h3>
          </Col>
        </Row>

        <Row className='text-center text-lg-start'>
        <p className='PFieldTitle pb-4 pb-lg-2'>¿Cómo te llamás?</p>
        </Row>

        <Row className='pb-4'>
            <Col xs={12} lg={4}>
            { errors.txtNombre && touched.txtNombre && 
                <div> <p className='PError'> {errors.txtNombre} </p> </div> 
                }

                <Field 
                name="txtNombre" 
                type="text" 
                placeholder="Nombre" 
                className={"form-control pb-2 pt-2 " + (errors.txtNombre && touched.txtNombre ? 'is-invalid' : '')}
                />

                <p className='PFieldHelpText  pt-2'> Obligatorio*</p>
            </Col>
            <Col className='pt-4 pt-lg-0' xs={12} lg={4}>
            { errors.txtApellido && touched.txtApellido && 
                <div> <p className='PError'> {errors.txtApellido} </p> </div> 
                }
                <Field 
                name="txtApellido" 
                type="text" 
                placeholder="Apellido" 
                className={"form-control pb-2 pt-2 " + (errors.txtApellido && touched.txtApellido ? 'is-invalid' : '') }
                />
                <p className='PFieldHelpText  pt-2'> Obligatorio*</p>
            </Col>
        </Row>

        <Row className='pb-4 pt-3 pt-lg-0' >
            <Col className='text-center text-lg-start' xs={12} lg={8}>
                <p className='PFieldTitle pb-4 pb-lg-2'>¿Cuál es tu correo electrónico?</p>
                { errors.txtEmail && touched.txtEmail && 
                <div> <p className='PError'> {errors.txtEmail} </p> </div> 
                }

                <Field 
                name="txtEmail" 
                type="text" 
                placeholder="ejemplo@ejemplo.com" 
                className={"form-control pb-2 pt-2 " + (errors.txtEmail && touched.txtEmail ? 'is-invalid' : '')} 
                />

                <p className='PFieldHelpText pt-2 '>Obligatorio*</p>
            </Col>
        </Row>

        <Row>
            <Col className='text-center text-lg-start' xs={12} lg={8}>
                <p className='PFieldTitle pb-4 pb-lg-2'>Volvé a escribir tu correo</p>
                { errors.txtEmailConfirm && touched.txtEmailConfirm &&
                <div> <p className='PError'> {errors.txtEmailConfirm} </p> </div>
                }
                <Field 
                name="txtEmailConfirm" 
                type="text" 
                placeholder="ejemplo@ejemplo.com" 
                className={"form-control pb-2 pt-2 " + (errors.txtEmailConfirm && touched.txtEmailConfirm ? 'is-invalid' : '')} />
                <p className='PFieldHelpText pt-2 '> Obligatorio*</p>
            </Col>
        </Row>

        <Row className='pt-5 '>
            <Col className='pt-3'>
                <Button bsPrefix='btnPrev' type='button' onClick={()=> props.prev(values)} >&lt; Volver</Button>
            </Col>

            <Col className='text-center'>
                <Button bsPrefix='btnNext' type='submit'>Siguiente</Button>
            </Col>
      </Row>
      </Form>
     )}
    </Formik>
    )}

    export default StepTwo;