const URL = 'https://api-rest.depcsuite.com';


export async function login(email, clave) {
  let formData = new FormData();
  formData.append('email', email);
  formData.append('documento', clave);
  formData.append('grupo_id', '39');

  try {
    let config = {
        method: 'POST',
        header: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: formData
    };

    let response = await fetch(`${URL}/login`, config);
    let responseJson = await response.json();
    return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getTokenCSRF(){
  try{
    let response = await fetch(`${URL}/getToken`);
    let responseJson = await response.json();
    return responseJson;
  }
  catch(e){
    console.error(e);
  }
}


export async function getDescuentos() {
  try {
      let response = await fetch(`${URL}/obtenerPromociones`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getDescuentosPorId(id) {
  try {
      let response = await fetch(`${URL}/obtenerDescuento?idCupon=${id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getRubros() {
  try {
      let response = await fetch(`${URL}/obtenerRubros`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getDescuentosPorRubro(){
  try {
      let response = await fetch(`${URL}/obtenerDescuentosPorRubro`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getBanners(){
  try {
      let response = await fetch(`${URL}/obtenerBanners`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getQR(idbeneficiario,idcupon){
  try {
      let response = await fetch(`${URL}/verificar-uso-cupon?idBeneficiario=${idbeneficiario}&idCupon=${idcupon}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function postVisitaQR(idbeneficiario,idcupon,idcomercio){

  let formData = new FormData();
  formData.append('idBeneficiario', idbeneficiario);
  formData.append('idCupon', idcupon);
  formData.append('idComercio', idcomercio);
  try {
    let config = {
      method: 'POST',
      header: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'X-CSRF-TOKEN': 'JxTcvdTEKIz0ZBfYVvjbIbVlYRBmELEL3er8SaJF'
      },
      
      body: formData

  };
      let response = await fetch(`${URL}/visita-cod-qr`,config);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getAllLocales(){
  try {
      let response = await fetch(`${URL}/getAllLocales`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getAllEmpresas(){
  try {
      let response = await fetch(`${URL}/getAllEmpresas`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}
export async function getBeneficiarioId(id){
  try {
      let response = await fetch(`${URL}/getBeneficiarioId?idBeneficiario=${id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getDestacados(){
  try {
      let response = await fetch(`${URL}/obtenerDestacados`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getAllCulturas(){
  try {
      let response = await fetch(`https://app.depcsuite.com/notas-culturales/obtenerTodas`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e); 
  }
}

export async function getData($url){
  try {
      let response = await fetch(`${URL}${$url}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}
export async function getNotaId($id){
  try {
      let response = await fetch(`${URL}/getNota?idNota=${$id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getRubroId($id){
  try {
      let response = await fetch(`${URL}/getRubroId?idRubro=${$id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getNotaRubro($id){
  try {
      let response = await fetch(`${URL}/getNotaRubro?idRubro=${$id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getCircuitoId($id){
  try {
      let response = await fetch(`${URL}/getCircuito?idCircuito=${$id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getNotasCircuitos($id){
  try {
      let response = await fetch(`${URL}/getCircuitoCoordenadas?id=${$id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}

export async function getProductoId($id){
  try {
      let response = await fetch(`${URL}/getProductoId?idProducto=${$id}`);
      let responseJson = await response.json();
      return responseJson;
  } catch (e) {
      console.error(e);
  }
}
// http://localhost:3000');
// return json_encode($aData);
// }
