//import Creed from '../assets/images/mockuo-tarjeta-DePC-pass_foto.png'
import Creed from '../../assets/images/cardshadow.svg'
import './footercred.css'
import QREjemplo from '../../assets/images/qr-ejemplo.svg'
import QR from '../FooterQR'
import LogoDePC from '../../assets/images/logoDePCBig.svg'
import MiniApple from '../../assets/images/miniapple.svg';
import MiniGoogle from '../../assets/images/minigoogle.svg';


export default function FooterCred() {
  return (
    <div className='FooterCred'>
        <div className='FooterCredContainImg'>
            <img className='FooterCredImg'  src={Creed} alt='footer'/>
        </div>
        <div className='FooterCredContainText'>
            <div className='ContainLogoPC'>
              <img src={LogoDePC} alt="logo depc" />
            </div>
            <h2 className='H1FooterCred'>¡Quiero mi credencial ahora!</h2>
            <QR qr={QREjemplo}/>
         </div>
         <div className='ContainAppsMobil'>
            
            <img src={MiniApple} alt="logo apple" className='MobileLogo'/>
            <img src={MiniGoogle} alt="logo google" className='MobileLogo'/>
        </div>

    </div>
  )
}
