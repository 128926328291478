import './cultura.css';
import { Row, Col, InputGroup, Form  } from 'react-bootstrap';
import LeftMenu from './LeftMenu';
import Calendar from '../../assets/images/icon-calendar.svg';
import Star from '../../assets/images/icon-star.svg';
import { FaSistrix } from "react-icons/fa";
import { BsSliders } from "react-icons/bs";
import Carrusel from './Carrusel.js';
import CarruselLittle from './CarruselLittle.js';
import Divider from '../Divider';
import Map from './Map.js';
import {useState, useEffect} from 'react';
import * as Service from '../../services';
import Card from './Card';
import VideoCultura from '../../assets/videos/portada_1920x810.mp4';


export default function Cultura() {
    const [filtro, setFiltro] = useState([]);
    const [filtroDia, setFiltroDia] = useState([]);
    const [cultura , setCultura] = useState([]);
    const [busqueda , setBusqueda] = useState('');
    const [rubro , setRubro] = useState();
    const [prox, setProx] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        const rubros = await Service.getData('/getAllRubros');
        const data = await Service.getData('/getAllNotas');
        const proxNotas = await Service.getData('/nextNotas');
        
        setRubro(rubros);
        setCultura(data);
        setProx(proxNotas);
        }
        fetchData();
    }
    , []);
    
  return (
    <Row className='p-0 m-0'>
        <div className='contain-video-cultura p-0 m-0'>
            <video className='cultura-video' preload="metadata" autoPlay loop muted>
                <source src={VideoCultura + '#t=0.5'} type='video/mp4' />
            </video>
        </div>
        <Col md={3} className='d-none d-xl-block pt-5'>
            <div style={{width:'300px'}} className='contaner-leftmenu-cultura'>
                <LeftMenu filtroDia={filtroDia} setFiltroDia={setFiltroDia}  filtro={filtro} setFiltro={setFiltro}  set={setBusqueda} />
            </div>
        </Col>
        <Col xs={12} md={9} className='p-0 m-0'>
            <Row className='fondo-cultura px-0 mx-0 pb-0'>
                <Row className='mx-0 pt-5'>
                    <Col xs={12}     className=' ps-0 pt-3'>
                        <Row style={{height:'400px'}} className='align-items-end'>
                            <Col xs={9} md={9} className='text-end text-lg-center  px-0 offset-3 offset-lg-0' >
                                <h3 className='cultura-overlay-title'>¡Armá tu plan de salidas con amigos y disfrutá de los beneficios!</h3>
                            </Col>
                            <Col xs={9} className='text-end text-lg-center offset-3 offset-lg-0 d-lg-none px-0'>
                                <p className='cultura-subtitle-header'>¿Sabías que podés acceder a distintos eventos con la credencial y obtener beneficios en los comercios amigos?
                                <br/> 
                                <span style={{fontWeight:'700'}}>¡Elegí tu mejor plan de salidas!</span>
                                </p>
                            </Col>
                            <Col xs={3} md={3} className='text-end text-lg-center offset-6 offset-lg-0 px-0'>
                                <div className='contain-texto-img'>
                                    <p>Presentá tu <span style={{color:'var(--pink)',fontWeight:'600'}}>Credencial Estudiantil</span>  para acceder a los Beneficios.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='pt-5'>
                            <Col md={9} className='text-center ps-0 d-none d-lg-block pt-4'>
                                <p className='cultura-subtitle-header'>¿Sabías que podés acceder a distintos eventos con la credencial y obtener beneficios en los comercios amigos?
                                <br/> 
                                <span style={{color:'var(--pink)'}}>¡Elegí tu mejor plan de salidas!</span>
                                </p>
                            </Col>
                            <Col md={12} className='pt-4 d-none d-lg-block px-0 '>
                                <Divider isHorizontal style={{backgroundColor:"var(--grey)" }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            
            </Row>
            <Row className='pt-4  pe-lg-5 m-0'>
                <Row className='ContainSearchDescuentos  ms-1 ms-md-0 container-md pe-0  py-3 align-items-center'>
                    <Col xs={3} className='d-md-none'>
                        <div className='d-flex flex-row  align-items-center'>
                                <p className='PDescuentosRed pe-1'>Filtros</p>
                                <BsSliders color='var(--red)' fontSize='20px'/>
                        </div>
                    </Col>
                    <Col xs={5} md={6} style={{height:'32px'}} className='d-flex flex-row align-items-center '>
                        <p className='pe-3  PDescuentosBuscar d-none d-md-block'>Buscar</p>
                        <InputGroup>
                            <Form.Control
                                style={{borderRight:'none'}}
                                placeholder="Buscar"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                onChange = {(e)=>{
                                    setBusqueda(e.target.value)
                                }}
                                />
                            
                            <InputGroup.Text 
                                className='d-none d-md-block'
                                style={{backgroundColor:'white',borderLeft:'none'}} 
                                id="basic-addon2">
                                <FaSistrix color='var(--grey)' fontSize='17px'/>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                    {/* <Col xs={4} md={6} className='text-end align-middle'>
                        <div className='d-flex flex-row justify-content-end align-items-center'>
                            <p onClick={()=>setShowMap(!showMap)} className='PDescuentosRed cursor-pointer'>
                                Cerca mio
                            </p>
                            <HiOutlineLocationMarker color='var(--red)' fontSize='20px'/>
                        </div>
                    </Col> */}
                </Row>
                
               <Row className='mx-0 pt-5'>
                    <Col xs={12} className='p-0'>
                        <Row>
                            <Col xs={3} lg={1} className='p-0'>
                                <img src={Calendar} alt='...' />
                            </Col>
                            <Col  xs={8} lg={10}  className='my-auto mx-0 p-0 '>
                                <h2 className='subtitle-cultura' >Descubrí todo lo que podés hacer cerca tuyo</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mx-0 pt-4 pe-0 mx-lg-auto px-lg-auto'>
                    <Col xs={12} className='px-0'>
                        <Row className='container-lg px-0'>
                            <Col lg={12} className=''>
                                <Map busqueda={busqueda} filtro={filtro} filtroDia={filtroDia} />
                            </Col>
                        </Row>
                    </Col>
                </Row> 
                
            </Row>
            {busqueda || filtro.length > 0  || filtroDia.length > 0 ? 
                <Row id='cards' style={{minHeight:'600px'}} className='mx-0'>
                    <Col xs={12}  className=' px-0 pt-5'>
                        <Row className='p-0 m-0 container-lg'>
                        {cultura.map((item, index) => {
                            window.scrollTo(0, 500);

                            //comparar las fechas desde y hasta y si desde es mayor a hasta devolver true
                            const desde = new Date(item.fecha_desde);
                            const hasta = new Date(item.fecha_hasta);
                            const fechaActual = new Date();
                            const desdeFecha = desde.getTime();
                            const hastaFecha = hasta.getTime();
                            let fechaActualFecha;


                            if(desdeFecha < hastaFecha ){

                                fechaActualFecha = fechaActual.getTime() + (1000 * 60 * 60 * 24 * 30);
                                if(filtroDia.includes('ESTE MES') && !(fechaActualFecha <= hastaFecha)) 
                                {
                                    return null
                                };

                                //en fechaActualFecha obtener el dia de hoy y sumarle una semana
                                fechaActualFecha = fechaActualFecha + (1000 * 60 * 60 * 24 * 7);
                                if((filtroDia.includes('ESTA SEMANA') || filtroDia.includes('ESTE FINDE')) && !(fechaActualFecha <= hastaFecha)){
                                    return null
                                }

                                fechaActualFecha = fechaActual.getTime();
                                if(filtroDia.includes('HOY') &&  !(fechaActualFecha <= hastaFecha)) 
                                {
                                    return null
                                };
                               

                            
                            }

                            if(!busqueda && filtro.length === 0){
                                return(
                                    <Col key={index} xs={12} lg={3} className='p-0 mb-3'>
                                        <Card  content={item} />
                                    </Col>
                                )
                            }
                             

                            let arrayValues = Object.values(item);
                            arrayValues.splice(6,1);

                            if(busqueda){
                                if(filtro.length > 0){
                                    if(arrayValues.some(item => filtro.includes(item)) && item.titulo.toLowerCase().includes(busqueda.toLowerCase())){
                                        return(
                                            <Col key={index} xs={12} lg={3} className='p-0 mb-3'>
                                                <Card  content={item} />
                                            </Col>
                                        )
                                    }
                                }else{
                                    if(item.titulo.toLowerCase().includes(busqueda.toLowerCase())){
                                        return(
                                            <Col key={index} xs={12} lg={3} className='p-0 mb-3'>
                                                <Card  content={item} />
                                            </Col>
                                        )
                                    }
                                }
                            }
                            if(filtro.length > 0 && !busqueda){
                                if(arrayValues.some(item => filtro.includes(item))){
                                    return(
                                        <Col key={index} xs={12} lg={3} className='p-0 mb-3'>
                                            <Card  content={item} />
                                        </Col>
                                    )
                                }
                            }
                        

                        })}
                            
                        </Row>
                    </Col>
                </Row>
             :null}
        { !busqueda && filtro.length === 0 && filtroDia.length === 0 ?
            <Row>
                <Row className='pt-5 mx-0 pe-0'>
                    <Col xs={12}>
                        <Row>
                            <Col xs={3} lg={1} className='p-0'>
                                <img src={Star} alt='...' />
                            </Col>
                            <Col xs={8} lg={10}  className='my-auto p-0 '>
                                <h2 className='subtitle-cultura' >¡Circuitos de salidas para hacer con amigos!</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='p-0'>
                        <CarruselLittle circuito />
                    </Col>

                </Row>
                <Row className='pt-5 mx-0 pe-0'>
                    <Col xs={12}>
                        <Row>
                            <Col xs={3} lg={1} className='p-0'>
                                <img src={Calendar} alt='...' />
                            </Col>
                            <Col xs={8} lg={10}  className='my-auto p-0 '>
                                <h2 className='subtitle-cultura' >¡Elegí el plan que más te guste!</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mx-0'>
                    <Col xs={12}  className='px-0 pt-5'>
                        {rubro && rubro.map((item, index) => 
                            {
                                return(
                                    <Row key={index} className='p-0 m-0 mb-3 container-lg'>
                                        <CarruselLittle rubro={item} />
                                    </Row>
                                    )
                            }
                        )}
                    </Col>
                </Row>
                <Row className='mx-0'>
                    {prox.length > 0 ?
                        <Col xs={12} className='px-0 pt-5'>
                            <Row className=' p-0 m-0 container-lg'>
                                    <Carrusel content={prox} icon={Calendar} titulo={'Próximas experiencias'} />
                            </Row>
                        </Col>
                        :null
                    }
                </Row>
                
            </Row>
            :null
        }
        </Col>
    </Row>
  )
}
