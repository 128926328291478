import {  Marker, InfoWindow } from "@react-google-maps/api";
import { useState, useEffect} from "react";
import { Link } from "react-router-dom";

export default function MarkerCustom({setSelect,select,nota}) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(select === nota.idnotacultural){
            setIsOpen(true);
        }
    }, [select]);
    
    return (
        <>
            <Marker
                icon={{
                    url:"https://app.depcsuite.com/storage/sistemacirca/rubros_culturales/"+ nota.logo_rubro,
                    scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={{ lat: parseFloat(nota.latitud), lng: parseFloat(nota.longitud) }}
                onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
                <InfoWindow
                    position={{ lat: parseFloat(nota.latitud), lng: parseFloat(nota.longitud) }}
                    onCloseClick={() => {
                        select === nota.idnotacultural ? setSelect(null) : console.log('no');
                        setIsOpen(false);
                    }}
                >
                    <div className="text-center">
                        <div>
                            <Link to={`/cultural/nota/${nota.idnotacultural}`}>
                                <img
                                    src={
                                        "https://app.depcsuite.com/storage/sistemacirca/imagenes_cultura/" + nota.imagen_principal
                                    }
                                    alt={nota.titulo}
                                    style={{width:'130px',height:'130px',objectFit:'cover'}}
                                />
                            </Link>
                        </div>
                        <p
                            style={{letterSpacing:'0px'}}
                        >{nota.titulo}</p>
                    </div>
                </InfoWindow>
            )}

        </>
    )
}
