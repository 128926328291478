import '../Subscribite/subscribite.css';
import { Button } from "react-bootstrap"

export default function CardSubscribite({Src,NroTitle ,Title, Desc, ButtonText}) {
  return (
    <div className='CardContainSub text-center'>
                <img src={Src} alt="..."/>
                <h3 className='CardTitleSub'><span style={{color:"red"}}>{NroTitle}.</span> {Title}</h3>
                <p className='CardDescSub pb-3'> {Desc} </p>
                <Button bsPrefix="SubscribiteButton"> {ButtonText} </Button>
     </div>
  )
}
