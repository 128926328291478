import '../Descubri/descubri.css';
import { Card } from "react-bootstrap"

export default function CardDescubri({Src, Title, TextButton}) {
  return (
    <Card className='CardDescubri '>
    <div className='ContainerImgDescubrilo'>
    <img className='ImgCardDescubrilo' src={Src} alt="Descubrilo" />
    </div>
    <Card.ImgOverlay>
        <Card.Title bsPrefix='TitleOverlay'>{Title}</Card.Title>
    </Card.ImgOverlay>
    
    <Card.Body bsPrefix='BodyCardDescubrilo'>
        <Card.Text bsPrefix='TextBodyCardDescubrilo'>
            {TextButton}
        </Card.Text>
    </Card.Body>
</Card> 
  )
}
