import {  Marker, InfoWindow } from "@react-google-maps/api";
import { useState} from "react";
import { Link } from "react-router-dom";

export default function MarkerCustom({local}) {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <>
        <Marker
            key={local.id}
            position={{ lat: parseFloat(local.latitud), lng: parseFloat(local.longitud) }}
            onClick={() => setIsOpen(true)}
        />
        {isOpen && (
            <InfoWindow
                position={{ lat: parseFloat(local.latitud), lng: parseFloat(local.longitud) }}
                onCloseClick={() => setIsOpen(false)}
            >
                <div className="text-center">
                    <div>
                        <Link to={`/descuentos/${local.idcupon}`}>
                        <img 
                            src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_ilustrativas/'+local.imagen_ilustrativa} 
                            alt={local.local}
                            style={{width:'130px',height:'130px'}}
                            />
                        </Link>
                    </div>
                    <p 
                    style={{letterSpacing:'0px'}}
                    >{local.local}</p>
                </div>
            </InfoWindow>
        )}
    
    </>
  )
}
