import {Row,Col,Container} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import './descuento.css'

export default function CardDescuento({content}) {
    const [tipoCompra, setTipoCompra] = useState('');
 useEffect(() => {
    if(content.tipo_compra === 'Online'){
        setTipoCompra('La compra es online')
    }
    else if(content.tipo_compra === 'Presencial'){
        setTipoCompra('La compra se realiza en el local físico')
    }
    else if(content.tipo_compra === 'Online/Presencial'){
        setTipoCompra('La compra es online, limitada a una ubicación geografica')
    }
    else if(content.tipo_compra === 'Teléfono'){
        setTipoCompra('La compra se realiza por teléfono')
    }
    else if(content.tipo_compra === 'Correo Electrónico'){
        setTipoCompra('La compra se realiza por email')
    }

    }, [content])
  return (
    <Container className='ContainerCardDescuento mb-4'>
    <Row  className='p-3'>
        <Col xs={12} md={6} lg={5} xl={4} className='p-0 '>
                <div className='ContainImgDescuento'>
                    <img 
                        className='ImgDescuento'
                        src={'https://app.depcsuite.com/storage/sistemacirca/imagenes_descuentos/'+content.imagen_principal} 
                        alt="Descubrilo" 
                    />
                </div>
        </Col>
        <Col xs={12} md={6} lg={7} xl={8} className='ps-md-5'>
            <Row className='py-3 justify-content-start'>
                <Col className='pb-2' xs={6} md={12}  >
                <p className='PPromo'>{content.nombre_comercio}</p>
                </Col>
            <Col xs={6} md={12}>
                <div className='ContainLogoComercio'>
                <img 
                    className='LogoComercioDescuento'
                    src={'https://app.depcsuite.com/sistemacirca/logos_comercios/'+content.logo_comercio}
                    alt='Logo'
                    />
                </div>
                </Col>
            </Row>  
            <Row className='pt-2'>
                <p className='PPromocion'>{content.promocion}</p>
            </Row>  
            <Row className='pt-4'>
                 {tipoCompra? <p className='PTipoCompra'>{tipoCompra}</p>: null}
             </Row>
        </Col>

               
    </Row>
    </Container>
  )
}
