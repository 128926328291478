import { Row, Col, Button } from 'react-bootstrap';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import Card from '../../assets/images/mockuo-tarjeta-DePC-pass.png';
import Divider from '../Divider';

import ProgressBarPrimera from '../../assets/images/progress-form-1.svg';
import ProgressBarSegunda from '../../assets/images/progress-form-2.svg';
import ProgressBarTercera from '../../assets/images/progress-form-3.svg';
import ProgressBarCuarta from '../../assets/images/progress-form-4.svg';

import ProgressBarMobilePrimera from '../../assets/images/progress-form-1-mobile.svg';
import ProgressBarMobileSegunda from '../../assets/images/progress-form-2-mobile.svg';
import ProgressBarMobileTercera from '../../assets/images/progress-form-3-mobile.svg';
import ProgressBarMobileCuarta from '../../assets/images/progress-form-4-mobile.svg';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import './formcredencial.css';


export default function FormCredencial() {
  const [data, setData] = useState({
    lstCentro: '',
    txtNombreCentro: '',
    txtNombre: '',
    txtApellido: '',
    txtEmail: '',
    txtEmailConfirm: '',
    txtDireccion:'',
    txtDireccionNumero:'',
    txtDireccionPiso:'',
    txtDireccionDpto:'',
    txtDireccionCp:'',
    lstLocalidad: '',
    lstProvincia: '',
    txtDetalles: '',
    lstTarjetaTipo: '',
    txtTarjetaNombre: '',
    txtTarjetaNumero: '',
    lstTarjetaVencimientoMes: '',
    lstTarjetaVencimientoAño: '',
    txtTarjetaCVV: '',
    txtTarjetaDNI: '',
  
  });
  const [step, setStep] = useState(0);
  const [enviado, setEnviado] = useState(false);

  const makeRequest = (formData) => {
    setEnviado(true);
  }

  const handleNextStep = (newData, final = false) => {
    setData(prev => ({...prev, ...newData}));
    
    if (final) {
      makeRequest(newData);
      return;
    }

    setStep(prev => prev + 1);
  }

  const handlePrevStep = (newData) => {
    setData(prev => ({...prev, ...newData}));
    setStep(prev => prev - 1);
  }

  const steps=[
    <StepOne next={handleNextStep} data={data} />,
    <StepTwo next={handleNextStep} prev={handlePrevStep} data={data}/>,
    <StepThree next={handleNextStep} prev={handlePrevStep} data={data}/>,
    <StepFour next={handleNextStep} prev={handlePrevStep} data={data}/>,
  ];

  return (
   
    <div className='pt-5  container'>
        <Row className='pt-2' >
            <Col xs={12} lg className='text-center pt-5' >
                <h2 className='H2Pink36px  pt-5 mt-3 text-center text-lg-start' style={{textAlign:"left"}}>{enviado ? "Gracias":"Pedí tu credencial en 4 pasos"}</h2>
                {enviado ? (
                  <p className='PGrey20px pb-3 text-center text-lg-start' style={{textAlign:"left"}}><bold>¡Felicitaciones! </bold>¡Tu pago será validado en 24 hs <br/> y  nos pondremos en contacto con vos!</p>
                  ):(
                  <p className='PGrey20px pb-3 text-center text-lg-start' style={{textAlign:"left"}}>Los primeros 3 meses es GRATIS para <br/> estudiantes de DePC y luego $800 por mes.</p>
                  )
                }
                
                {window.innerWidth > 968 ? (
                  <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />
                ):(
                  <></>
                  )
                }
                


            </Col>
            <Col sx={4} className="text-center d-none d-lg-block">
                <div style={{width:"520px",height:"371px"}}>
                <img className='ImgCard' src={Card} alt="tarjeta"/>
                </div>
            </Col>
        </Row>
        {enviado ? ( 
        <>
          <Row>
          <Link to="/">
            <Button bsPrefix='btnNext' > 
              Volver al Home
                </Button>
          </Link>
          </Row>
        
        </>
        ) : (
        <>
        <Row className='justify-content-center justify-content-lg-start' >
            <Col className='text-center justify-content-center'  xs={9} lg={7} >
             
                <img className='img-fluid'  src={ProgressBarPrimera} alt="progress bar" style={step === 0 && window.innerWidth > 968  ? {display:"block"} : {display:"none"} }/> 
                <img className='img-fluid' src={ProgressBarSegunda} alt="progress bar" style={step === 1 && window.innerWidth >  968  ? {display:"block"} : {display:"none"} }/> 
                <img className='img-fluid' src={ProgressBarTercera} alt="progress bar" style={step === 2 && window.innerWidth >  968 ? {display:"block"} : {display:"none"} }/> 
                <img className='img-fluid'  src={ProgressBarCuarta} alt="progress bar" style={step === 3 && window.innerWidth >  968  ? {display:"block"} : {display:"none"} }/> 

                <img  src={ProgressBarMobilePrimera} alt="progress bar" style={step === 0 && window.innerWidth <  968 ? {display:"block"} : {display:"none"} }/>
                <img  src={ProgressBarMobileSegunda} alt="progress bar" style={step === 1 && window.innerWidth <  968 ? {display:"block"} : {display:"none"} }/>
                <img  src={ProgressBarMobileTercera} alt="progress bar" style={step === 2 && window.innerWidth <  968 ? {display:"block"} : {display:"none"} }/>
                <img  src={ProgressBarMobileCuarta} alt="progress bar" style={step === 3 && window.innerWidth <  968 ? {display:"block"} : {display:"none"} }/>
              </Col>
        </Row>
        <Row className='pt-5'>
            <Col xs={12}>
            {steps[step]}
            </Col>
        </Row>
        </>) }
    </div>
  )
}




