// ==================PAGES==========================
import Home from './pages/Home';
import Suscripcion from './pages/Suscripcion';
import Descuentos from './pages/Descuentos';
import NotFound from './pages/NotFound';
import Descuento from './pages/Descuento';
import Login from './pages/Login';
import MyAccount from './pages/MyAccount';
import Viajes from './pages/Viajes';
import Cultura from './pages/Cultura';
import NotaCultural from './pages/NotaCultural';
import Marketplace from './pages/Marketplace';
import Producto from './pages/Producto';
import Circuitos from './pages/Circuitos';

//=====================LAYOUTS============
import LayoutBasic from './layout/layout-basic';

//==================STYLES====================
import './App.css'; 

//=====================ROUTES====================
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
       <Routes>
          <Route  element={<LayoutBasic footer={false} />} >
            <Route path="/login" element={<Login />} />
            <Route path="/micuenta" element={<MyAccount/>} />
          </Route>

          <Route element={<LayoutBasic/>}>
            <Route path="/cultural" element={<Cultura />} />
            <Route path="/cultural/nota/:id" element={<NotaCultural />} />
            <Route path="/cultural/circuitos/:id" element={<Circuitos />} />
            <Route path="/escapadas" element={<Viajes />} />
            <Route path="/suscripcion" element={<Suscripcion title="Suscripción"/>} />

            <Route path="/marketplace/producto/:id" element={<Producto />} />
            <Route path="/descuentos/:id" element={<Descuento title="Descuento"/>} />

            <Route index  element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<LayoutBasic footerColor={'var(--light-grey)'}/>}>
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/descuentos" element={<Descuentos title="Descuentos"/>} />
          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
