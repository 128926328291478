import { Row, Col, Button } from "react-bootstrap";
import HP1 from "../../assets/images/HP_1.png";
import HP2 from "../../assets/images/HP_2.png";
import HP3 from "../../assets/images/HP_3.png";
import Arrow from "../../assets/images/icon-arrow.svg";
import LogoHp from "../../assets/images/logo_hp.svg";
import Divider from "../Divider";
import CashBack from '../../assets/images/icon-cashback.svg'
import Ahora12 from '../../assets/images/icon-ahora12.svg'
import Envio from '../../assets/images/icon-envio.svg'
import CardMarket from "../Marketplace/CardMarket";
import './marketplaceproducto.css';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import * as Services from '../../services'



export default function MarketPlaceProducto() {
    const [data, setData] = useState([]);
    const [carrusel, setCarrusel] = useState([]);
    let { id } =  useParams();
    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            const result = await Services.getProductoId(id);
            const resultCar = await Services.getData('/getProductos');
            console.log(result);
            setData(result);
            setCarrusel(resultCar);
        }
        fetchData();
    } , [id]);


  return (
    <>
    <div className="container py-5">
        <Row>
            <Col xs={12}>
                <p style={{fontSize:'16px',}}><Link style={{color:'var(--font-grey)'}} to='/marketplace'><span style={{textDecoration:'underline'}}>MarketPlace </span></Link>&gt; {data.nombre}</p>
            </Col>
        </Row>

        <Row className="pt-5">
            <Col xs={7}>
                <Row>
                    <Col style={{height:'400px'}} xs={12}>
                        <img className="img-cultura-card" style={{objectFit:'contain'}} src={'https://sistema.depcsuite.com/productos/'+ data.imagen} alt='...' />
                    </Col>
                </Row>
                {/* <Row className="pt-3 justify-content-center align-items-center">
                    <Col md={1} style={{height:'35px',width:'35px'}}>
                        <img className="p-auto m-auto" style={{height:'100%',width:'100%'}}  src={Arrow} alt='...' />
                    </Col>
                    <Col md={2}>
                        <img 
                        className="img-preview-marketpalce cursor-pointer"
                        src={HP1} 
                        onClick={() => setPhotos(HP1)} 
                        alt='...' 
                        />
                    </Col>
                    <Col md={2}>
                        <img 
                        className="img-preview-marketpalce cursor-pointer" 
                        src={HP2} 
                        onClick={() => setPhotos(HP2)}
                        alt='...' 
                        />
                    </Col>
                    <Col md={2}>
                        <img 
                        className="img-preview-marketpalce cursor-pointer" 
                        src={HP3} 
                        onClick={() => setPhotos(HP3)}
                        alt='...' 
                        />
                    </Col>
                    <Col md={2}>
                        <img 
                        className="img-preview-marketpalce cursor-pointer" 
                        src={HP3} 
                        onClick={() => setPhotos(HP3)}
                        alt='...' 
                        />
                    </Col>
                    <Col md={1} style={{height:'35px',width:'35px'}}>
                        <img className="p-auto m-auto" style={{height:'100%',width:'100%',transform:'rotate(180deg)'}} src={Arrow} alt='...' />
                    </Col>
                </Row> */}

            </Col>
            <Col className='contain-text-producto px-4' xs={5}>
                <Row className="pt-4">
                    <Col className="text-start" xs={12} style={{height:'50px'}}>
                        {data.foto && <img style={{height:'100%'}} src={'https://sistema.depcsuite.com/furorba/images/productos/'+data.foto} alt='...' />}
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col xs={12}>
                        <p className="text-title-product">{data.nombre}</p>
                    </Col>
                </Row>
                <Row className="text-start">
                   { data.precio_oferta ?
                   <>
                   <div className='ContainOldPrice' style={{justifyContent:'start'}}>
                        <p className="OldPriceMarket">$ {data.precio_pesos}</p>
                        <p className="DiscountMarket">{data.porcentaje_descuento}% OFF</p>
                    </div>
                    <p className="PriceMarket" style={{textAlign:'left'}}>$ {data.precio_oferta}</p>
                    </>: <p className="PriceMarket" style={{textAlign:'left'}}>$ {data.precio_pesos}</p>
                    }
                </Row>
                <Row className="pt-3">
                    <Col xs={12} className='py-2'>
                        <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                    </Col>
                    <Col className="d-flex flex-row" xs={12}>
                        <Row>
                            <Col className="text-center" xs={2}>

                                <img style={{height:'52px',width:'27px'}} src={Ahora12} alt='...' />
                            </Col>
                            <Col xs={10}>
                                <p className="ps-1 text-info-market">12 cuotas sin interés de {data.precio_oferta ? `$${(data.precio_oferta/12).toFixed(2)}`: `$${(data.precio_pesos/12).toFixed(2)}`}</p>
                            </Col>
                        </Row>
                    </Col>
                    { data.envio === "1" ? <>
                        <Col xs={12} className='py-2'>
                            <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                        </Col>

                        <Col className="d-flex flex-row" xs={12}>
                            <Row>
                                <Col className="text-center" xs={2} >
                                    <img  style={{height:'25px',width:'36px'}} src={Envio} alt='...' />
                                </Col>
                                <Col xs={9}>
                                    <p className="ps-1 text-info-market">Ver zonas de envío</p>
                                </Col>
                            </Row>
                        </Col>
                    </>
                    :
                    <></>
                    }
                    <Col xs={12} className='py-2'>
                        <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                    </Col>

                    <Col className="d-flex flex-row" xs={12}>
                        <Row>
                            <Col className="text-center" xs={2}>
                                <img  style={{height:'39px',width:'65px'}} src={CashBack} alt='...' />
                            </Col>
                            <Col xs={10}>
                                <p className="ps-1 text-info-market">Te devolvemos el 2% de tu compra para que puedas reutilizarlo.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className='py-2'>
                        <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col xs={12}>
                        <Button href={data.url_carrito} bsPrefix="btn-marketplace">Comprar</Button>
                    </Col>
                </Row>

            </Col>
        </Row>
    </div>
    <div style={{backgroundColor:'var(--light-grey)'}}>
        <div  className="container">
            <Row  className="py-5">
                    <Col className="pb-3" xs={12}>
                        <p style={{fontSize:'16px',fontWeight:'600'}}>Personas interesadas en este producto también vieron</p>
                    </Col>
                    <Row>
                        {
                            carrusel.map((item,index) =>{
                                return(
                                    <Col  md={3} key={index}>
                                        <CardMarket content={item} destacado/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                
            </Row>
        </div>
    </div>

    <div className="container py-5">
        <div className="mx-5">

        <div className='contain-descripcion-nota-cultural' dangerouslySetInnerHTML={ {__html: data.descripcion}}></div>

        </div>
    </div>
    
    {/* <div className="container py-5">
        <div className="mx-5">
            <Row className="py-5 text-center">
                <Col className="pb-3" xs={12}>
                    <img style={{height:'50px'}} src={LogoHp} alt='...' />
                </Col>
                <Col className="pb-3" xs={12}>
                    <p style={{fontSize:'24px',fontWeight:'700', letterSpacing:'0px'}}>Notebook HP 15,6" Intel Celeron 4GB 1TB 15-DW1080LA</p>
                </Col>
                <Col className="pb-3" xs={12}>
                    <p style={{fontSize:'14px', letterSpacing:'0px'}}>SKU: 888888888</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p className="text-title-product">Características técnicas</p>
                </Col>
                <Col xs={12}>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                </Col>
            </Row>

            <Row>
                <Col xs={4} className='pt-3'>
                    <p className="text-title-comparacion">Procesador</p>
                </Col>
                <Col xs={8}>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Procesador</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">Intel Celeron</p>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Modelo procesador</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="pt-3">
                <Col xs={12}>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                </Col>
            </Row>

            <Row>
                <Col xs={4} className='pt-3'>
                    <p className="text-title-comparacion">Memoria</p>
                </Col>
                <Col xs={8}>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Disco Rigido</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">1 TB</p>
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Memoria RAM</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">4 GB</p>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Tipo de Disco</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">Rigido</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="pt-3">
                <Col xs={12}>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                </Col>
            </Row>

            <Row>
                <Col xs={4} className='pt-3'>
                    <p className="text-title-comparacion">Sistema Operativo</p>
                </Col>
                <Col xs={8}>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">SO</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">Windows 10 Home 64 bits</p>
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Software iniciales</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">Netflix, Dropbox</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="pt-3">
                <Col xs={12}>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                </Col>
            </Row>

            <Row className="pb-5">
                <Col xs={4} className='pt-3'>
                    <p className="text-title-comparacion">Imagen</p>
                </Col>
                <Col xs={8}>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Tamaño de pantalla</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">15,6 pulgadas</p>
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Resolución de pantalla</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">1366 x 768 px</p>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Pantalla táctil</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">No</p>
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Tecnología de pantalla</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">BrightView</p>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor:'#F1F1F1'}} className='py-2'>
                        <Col xs={6}>
                            <p className="text-subtitle-comparacion">Placa de video</p>
                        </Col>
                        <Col xs={6}>
                            <p className="text-comparacion">Gráficos Intel® UHD 600</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="pt-5">
                <Col xs={12}>
                    <p className="text-title-comparacion"> Descripción </p>
                    <Divider isHorizontal style={{backgroundColor:'var(--grey)'}} />
                </Col>
            </Row>
            <Row>
                <Col className="pt-5" xs={6}>
                    <p className="text-title-comparacion"> Diseño </p>
                    <p className="text-comparacion pt-2">
                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos).
                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)
                    </p>
                </Col>
                <Col className="pt-5" xs={6}>
                    <p className="text-title-comparacion"> Conectividad </p>
                    <p className="text-comparacion pt-2">
                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos). Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)

                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)
                    </p>
                </Col>
                <Col className="pt-5" xs={6}>
                    <p className="text-title-comparacion"> Desempeño </p>
                    <p className="text-comparacion pt-2">
                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos).
                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)
                    </p>
                </Col>
                <Col className="pt-5" xs={6}>
                    <p className="text-title-comparacion"> Batería </p>
                    <p className="text-comparacion pt-2">
                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos). Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)

                        Intel® Celeron® N4020 (frecuencia base de 1,1 GHz, frecuencia de ráfaga de hasta 2,8 GHz, 4 MB de caché L2 y 2 núcleos)
                    </p>
                </Col>
            </Row>
        </div>
    </div>
    <div style={{backgroundColor:'var(--light-grey)'}} className='py-5'>
        <div className="container">
            <div className="contain-comparacion p-5">
                <Row className="py-2">
                    <Col xs={4}>
                        <p className="text-title-comparacion">Compará con estos productos similares</p>
                    </Col>
                    <Col xs={2}>
                        <img src={HP1} className="img-fluid" alt="arrow" />
                        <p style={{fontSize:'14px'}} className="TitleMarket text-center p-0">Nombre Producto Nombre Producto</p>
                    </Col>
                    <Col xs={2}>
                        <img src={HP2} className="img-fluid" alt="arrow" />
                        <p  style={{fontSize:'14px'}}  className="TitleMarket text-center p-0">Nombre Producto Nombre Producto</p>
                    </Col>
                    <Col xs={2}>
                        <img src={HP3} className="img-fluid" alt="arrow" />
                        <p  style={{fontSize:'14px'}}  className="TitleMarket text-center p-0">Nombre Producto Nombre Producto</p>
                    </Col>
                    <Col xs={2}>
                        <img src={HP1} className="img-fluid" alt="arrow" />
                        <p  style={{fontSize:'14px'}}  className="TitleMarket text-center p-0">Nombre Producto Nombre Producto</p>
                    </Col>
                </Row>
                <Row style={{backgroundColor:'#F1F1F1'}}  className="py-2" >
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Precio</p>
                    </Col>
                    <Col style={{borderRight:'1px solid var(--light-grey)'}} className="text-center"  xs={2}>
                        <p className="PriceMarket">$88.888</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="PriceMarket">$88.888</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="PriceMarket">$88.888</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="PriceMarket">$88.888</p>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Marca</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <img src={LogoHp} className="img-fluid" alt="arrow" />
                    </Col>
                    <Col className="text-center" xs={2}>
                        <img src={LogoHp} className="img-fluid" alt="arrow" />
                    </Col>
                    <Col className="text-center" xs={2}>
                        <img src={LogoHp} className="img-fluid" alt="arrow" />
                    </Col>
                    <Col className="text-center" xs={2}>
                        <img src={LogoHp} className="img-fluid" alt="arrow" />
                    </Col>
                </Row>
                <Row style={{backgroundColor:'#F1F1F1'}}  className="py-2">
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Procesador</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">Intel® Celeron®</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">Intel® Celeron®</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">Intel® Celeron®</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">Intel® Celeron®</p>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Mod. procesador</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">N4020</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">N4020</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">N4020</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">N4020</p>
                    </Col>
                </Row>
                <Row style={{backgroundColor:'#F1F1F1'}}  className="py-2">
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Velocidad CPU</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1,1 GHz</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1,1 GHz</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1,1 GHz</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1,1 GHz</p>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Disco Rígido</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1TB</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1TB</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1TB</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">1TB</p>
                    </Col>
                </Row>
                <Row style={{backgroundColor:'#F1F1F1'}}  className="py-2">
                    <Col xs={4}>
                        <p className="text-comparacion-title-grey">Memoria RAM</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">4GB</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">4GB</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">4GB</p>
                    </Col>
                    <Col className="text-center" xs={2}>
                        <p className="text-comparacion-black">4GB</p>
                    </Col>
                </Row>
            </div>
        </div>
    </div> */}
        </>
   
  )
}
