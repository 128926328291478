import { GoogleMap, useLoadScript, Polyline } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import * as Service from "../../services";
import MarkerCustom from "./MarkerCustom";


export default function Map({id}) {
    const [notas, setNotas] = useState();
  
    const [center, setCenter] = useState({
        lat: -34.6037,
        lng: -58.3816,
    });
 
  useEffect(() => {
    const fetchData = async () => {
        const data = await Service.getNotasCircuitos(id);
        setCenter({
            lat: parseFloat(data[0].latitud),
            lng: parseFloat(data[0].longitud),
            });
        setNotas(data);
    }
    fetchData();
  }, [id]);

    

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ,
    })
    if (!isLoaded) {
        return <div>Loading...</div>;
    }else{

    return (
        <GoogleMap
            id="map"
            mapContainerStyle={{
                height: "70vh",
                width: "100%",
            }}
            zoom={15}
            center={center}
        >
            {notas && notas.map((nota, index) => {
                return(
                <>

                <MarkerCustom key={index} nota={nota} />
                </>
                )    
            })}

            {notas && <Polyline
                path={notas && notas.map((nota, index) => {
                    return({
                        lat: parseFloat(nota.latitud),
                        lng: parseFloat(nota.longitud),
                    })
                }
                )}
                options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                }}
            />}


           


        </GoogleMap>
    );
}
}
