import {Row, Col } from 'react-bootstrap';
import Divider from '../Divider';

export default function DireccionesPerfil() {
  return (
    <><div className='container'>
        <Row className='pt-5 '>
            <Col xs={12}>
                <h3 className='main-menu-title'>Mis Direcciones</h3>
            </Col>
        </Row>

        <Row className='pt-4'>
            <Col xs={12}>
                <p className='main-menu-categoria'>Casa</p>
                <p className='main-menu-value fw-bold'>Av. Rivadavia 4563 9° E</p>
                <p className='main-menu-value'>Capital Federal, Buenos Aires</p>
                <p className='main-menu-value'>CP: 1569</p>
                <p className='main-menu-value'>Juan</p>
            </Col>
            <Col xs={12} className='text-end'>
                <p className='main-menu-value text-decoration-underline'>Eliminar Direccion</p>
            </Col>
            <Col xs={12} className='pt-2'>
            <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />
            </Col>
        </Row>

        <Row className='pt-4'>
            <Col xs={12}>
                <p className='main-menu-categoria'>Casa</p>
                <p className='main-menu-value fw-bold'>Av. Rivadavia 4563 9° E</p>
                <p className='main-menu-value'>Capital Federal, Buenos Aires</p>
                <p className='main-menu-value'>CP: 1569</p>
                <p className='main-menu-value'>Juan</p>
            </Col>
            <Col xs={12} className='text-end'>
                <p className='main-menu-value text-decoration-underline'>Eliminar Direccion</p>
            </Col>
            <Col xs={12} className='pt-2'>
            <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />
            </Col>

        </Row>
        </div>
</>
  )
}
