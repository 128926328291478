import {Row, Col, Button} from 'react-bootstrap';
import Divider from '../Divider';

export default function DetallesPerfil({user}) {
  return (
    <>
     <Row className='pt-5'>
                    <Col xs={12}>
                        <h3 className='main-menu-title'>Detalles del Perfil</h3>
                    </Col>
                </Row>
                <Row className='pt-4'>
                    <Col xs={3}>
                        <p className='main-menu-categoria'>Nombre</p>
                        <p className='main-menu-value'>{user.nombre}</p>
                    </Col>
                    <Col xs={3}>
                        <p className='main-menu-categoria'>Apellido</p>
                        <p className='main-menu-value'>{user.apellido}</p>
                    </Col>
                    <Col xs={3}>
                        <p className='main-menu-categoria'>Email</p>
                        <p className='main-menu-value'>{user.email}</p>
                    </Col>
                    <Col xs={3}>
                        <p className='main-menu-categoria'>Teléfono</p>
                        <p className='main-menu-value'>{user.telefono}</p>
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col xs={12}>
                       <Divider isHorizontal style={{backgroundColor:"var(--grey)"}} />
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col xs={3}>
                        <p className='main-menu-categoria'>Contraseña</p>
                        <p className='main-menu-value'>*********</p>
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col xs={12}>
                        <Button bsPrefix='btn-change-password'>Editar</Button>
                    </Col>
                </Row>
    </>
  )
}
